import { ChatEvent } from 'socket/events';
import { GameEvent } from 'socket/events';
import { User } from 'types/User';
import { SocketService } from './SocketService';
import { GameUpdate, PresentUpdate } from 'types';

const namespace = '/game';

export class GameSocket extends SocketService {

  // initiate the connection, provide a namespace to connect to a specific area
  public connect(user: User, room: string = ''): SocketService {
    console.debug('[GameSocket](connect)');
    this.init(namespace);
    this.identify(user);

    if (room) {
      setTimeout(() => {
        this.joinRoom(room);
      }, 200);
    }
    return this;
  }

  // join an existing game
  public joinRoom(room: string): void {
    console.debug('[GameSocket](joinRoom): ' + room);
    this.socket.emit(GameEvent.JOINROOM, room);
  }

  // create a new game
  public createRoom(room: string): void {
    console.debug('[GameSocket](createRoom): ' + room);
    this.socket.emit(GameEvent.CREATEROOM, room);
  }  

  // send a message for the server to broadcast
  public getMessages(): void {
    console.debug('[GameSocket](getMessages)');
    this.socket.emit(ChatEvent.GETMESSAGES, null);
  }
  
  // send a message for the server to broadcast
  public sendMessage(message: string): void {
    console.debug('[GameSocket](sendMessage): ' + message);
    this.socket.emit(ChatEvent.MESSAGE, message);
  }

  // send a message for the server to broadcast
  public updateGame(data: GameUpdate): void {
    console.debug('[GameSocket](gameUpdate): ' + data);
    this.socket.emit(GameEvent.GAMEUPDATE, data);
  }

  // start the game
  public startGame(): void {
    console.debug('[GameSocket](startGame)');
    this.socket.emit(GameEvent.STARTGAME, null);
  }

  // start the game
  public pickPresent(presentId: string): void {
    console.debug('[GameSocket](pickPresent)');
    this.socket.emit(GameEvent.PICKPRESENT, presentId);
  }
  
  // send a message for the server to broadcast
  public addPresent(data: PresentUpdate): void {
    console.debug('[GameSocket](addPresent): ', data);
    this.socket.emit(GameEvent.PRESENTADDED, data);
  }
  
  
}
