import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import HomeScene from "scenes/HomeScene";
import GameScene from 'scenes/GameScene';
import CssBaseline from '@material-ui/core/CssBaseline';
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  app: {
    backgroundImage: `url(${process.env.PUBLIC_URL}/gift-pile.jpg)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    minHeight: '100vh',
  },
}));

function App() {
  const classes = useStyles();

  return (
    <div className={classes.app}>
      <CssBaseline/>
      <Router>
        <Switch>
          <Route path="/" exact component={HomeScene} />
          <Route path="/game/:room" exact component={GameScene} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;