import * as React from "react";

function SvgBigGiftboxWithRibbon(props) {
  return (
    <svg viewBox="0 0 441.721 441.721" width="1em" height="1em" {...props}>
      <path d="M363.861 115.619H327.38c11.508-7.815 17.807-17.881 16.321-28.252-2.232-15.589-21.835-26.063-48.778-26.063-5.124 0-10.389.383-15.648 1.137a68.34 68.34 0 00-9.903 2.19 47.28 47.28 0 00.776-8.392c0-32.063-21.49-56.24-49.987-56.24s-49.987 24.178-49.987 56.24c0 2.701.262 5.365.719 7.975a67.723 67.723 0 00-8.444-1.774 110.687 110.687 0 00-15.648-1.137c-26.943 0-46.546 10.474-48.778 26.063-1.486 10.371 4.813 20.437 16.321 28.252H77.859c-9.649 0-17.5 7.851-17.5 17.5v23.376c0 9.649 7.851 17.5 17.5 17.5h14.052V428.22c0 7.444 6.056 13.5 13.5 13.5H336.31c7.444 0 13.5-6.056 13.5-13.5V173.995h14.052c9.649 0 17.5-7.851 17.5-17.5v-23.376c-.001-9.649-7.851-17.5-17.501-17.5zM281.4 77.289a95.857 95.857 0 0113.521-.984c20.057 0 33.002 6.706 33.931 13.19 1.114 7.775-14.368 21.063-42.113 25.04a54.33 54.33 0 01-7.672.538c-13.654 0-25.814-4.86-33.209-9.146a107.983 107.983 0 004.139-4.462c3.628-4.156 8.438-10.343 12.451-17.79 5.598-2.993 11.991-5.388 18.952-6.386zM220.159 15c20.273 0 34.987 17.344 34.987 41.24 0 23.903-27.576 47.98-34.893 51.042-7.378-3.311-35.082-27.857-35.082-51.042.001-23.896 14.715-41.24 34.988-41.24zM112.868 89.494c.929-6.483 13.874-13.189 33.931-13.189 4.414 0 8.964.332 13.521.984 6.248.895 12.03 2.923 17.199 5.491 4.161 7.844 9.282 14.373 13.106 18.712a113.76 113.76 0 004.535 4.837c-7.449 4.182-19.278 8.745-32.507 8.745-2.598 0-5.179-.182-7.671-.538-27.746-3.978-43.228-17.265-42.114-25.042zm-5.957 163.929H213.36v173.298H106.911V253.423zM228.36 426.721V253.423h106.45v173.298H228.36zm106.45-188.298H228.36v-64.127h-15v64.127H106.911v-64.428H334.81v64.428zm31.551-81.928c0 1.355-1.145 2.5-2.5 2.5H77.859c-1.355 0-2.5-1.145-2.5-2.5v-23.376c0-1.355 1.145-2.5 2.5-2.5h286.002c1.355 0 2.5 1.145 2.5 2.5v23.376z" />
    </svg>
  );
}

export default SvgBigGiftboxWithRibbon;
