import { Card, CardActions, CardMedia, makeStyles } from '@material-ui/core';
import React, { useMemo } from 'react';
import { PresentUpdate } from 'types';

const useStyles = makeStyles((theme) => ({
  card: {
    width: '100%',
    marginBottom: '.5em',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  image: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  }
}));

interface AddImageProps {
  present: PresentUpdate;
  setFile: Function;
  file: File | undefined;
}

export function AddAnImage({ present, file, setFile}: AddImageProps) {
  const classes = useStyles();

  const imageUrl = useMemo(() => {
    if (file) {
      return URL.createObjectURL(file);
    }

    if (present.imageName) {
      return `${process.env.REACT_APP_API_SERVER}/static/${present.imageName}`;
    }

    return '';
  }, [file, present.imageName]);

  const pickFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files?.length) {
      alert('Something went wrong adding image!');
      return;
    }

    const file = e.target.files[0];

    if (!['image/png', 'image/jpeg', 'image/gif'].includes(file.type)) {
      alert('Thats not an image!');
      return;
    }

    if (file.size > 8000000) {
      alert('File is way too big! Please keep it to under 8MB');
      return;
    }

    setFile(file);
  }


  return (
    <>
      <h3>3) Add an image (optional, but recommended)!</h3>
      <p>If you want to add a picture of this gift, do it here! <strong>Add a picture of the gift you're adding to the pile, not a wrapped gift!</strong>This is optional and will only be shown once your gift is picked from the pile.</p>

      <label htmlFor='imageUpload'>
        <Card className={classes.card}>
          { imageUrl && <CardMedia
            className={classes.image}
            image={imageUrl}
            title="Your Gift"
          /> }
          <CardActions>
            Click here to add an image of your gift!
          </CardActions>
        </Card>
      </label>
      <input style={{display: 'none'}} type='file' id='imageUpload' onChange={(e) => pickFile(e)} /> 

    </>
  );
}

export default AddAnImage;