// @create-index

import  AniversaryGiftbox  from './AniversaryGiftbox.js';
// import  BigGiftboxAndGiftWithHeart  from './BigGiftboxAndGiftWithHeart.js';
import  BigGiftboxWithBun  from './BigGiftboxWithBun.js';
import  BigGiftboxWithLateralLace  from './BigGiftboxWithLateralLace.js';
import  BigGiftboxWithRibbon  from './BigGiftboxWithRibbon.js';
import  CylindricalGiftboxWithRibbon  from './CylindricalGiftboxWithRibbon.js';
import  GifboxWithLace  from './GifboxWithLace.js';
import  GifboxWithRibbonInTheMiddle  from './GifboxWithRibbonInTheMiddle.js';
import  GifboxWithRibbonOnTop  from './GifboxWithRibbonOnTop.js';
import  GifboxWrappedWithRibbon  from './GifboxWrappedWithRibbon.js';
import  GiftWithBow  from './GiftWithBow.js';
import  GiftWithRibbon  from './GiftWithRibbon.js';
import  GiftboxSide  from './GiftboxSide.js';
import  GiftboxWithABigRibbonOnCover  from './GiftboxWithABigRibbonOnCover.js';
import  GiftboxWithAHeart  from './GiftboxWithAHeart.js';
import  GiftboxWithAHeartOnSide  from './GiftboxWithAHeartOnSide.js';
import  GiftboxWithBigLace  from './GiftboxWithBigLace.js';
import  GiftboxWithBigLace1  from './GiftboxWithBigLace1.js';
import  GiftboxWithBigRibbon  from './GiftboxWithBigRibbon.js';
import  GiftboxWithBigRibbon1  from './GiftboxWithBigRibbon1.js';
import  GiftboxWithBigRibbon2  from './GiftboxWithBigRibbon2.js';
import  GiftboxWithBigRibbon3  from './GiftboxWithBigRibbon3.js';
import  GiftboxWithBun  from './GiftboxWithBun.js';
import  GiftboxWithFlower  from './GiftboxWithFlower.js';
import  GiftboxWithHearts  from './GiftboxWithHearts.js';
import  GiftboxWithLaceOnASide  from './GiftboxWithLaceOnASide.js';
import  GiftboxWithLongRibbon  from './GiftboxWithLongRibbon.js';
import  GiftboxWithRibbon  from './GiftboxWithRibbon.js';
import  GiftboxWithRibbonOnOneSide  from './GiftboxWithRibbonOnOneSide.js';
import  GiftboxWithRibbonOnTop  from './GiftboxWithRibbonOnTop.js';
import  GiftboxWithRibbonOnTop1  from './GiftboxWithRibbonOnTop1.js';
import  GiftboxWrapped  from './GiftboxWrapped.js';
import  HeartShapeGiftboxWithLace  from './HeartShapeGiftboxWithLace.js';
import  HeartShapeGiftboxWithRibbon  from './HeartShapeGiftboxWithRibbon.js';
import  HeartShappedGifboxWithRibbon  from './HeartShappedGifboxWithRibbon.js';
import  OpenBoxWithTwoHearts  from './OpenBoxWithTwoHearts.js';
import  OpenGitfboxWithTwoHearts  from './OpenGitfboxWithTwoHearts.js';
import  PolkaDotsGiftboxWithLace  from './PolkaDotsGiftboxWithLace.js';
import  RectangularGiftboxWithFlower  from './RectangularGiftboxWithFlower.js';
import  RoundGiftBoxWithLace  from './RoundGiftBoxWithLace.js';
import  RoundGiftboxWithFlower  from './RoundGiftboxWithFlower.js';
import  SquareGifboxWrapped  from './SquareGifboxWrapped.js';
import  SquareGifsoftWithBun  from './SquareGifsoftWithBun.js';
import  SquareGiftboxWithBigLace  from './SquareGiftboxWithBigLace.js';
import  SquareGiftboxWithBigRibbon  from './SquareGiftboxWithBigRibbon.js';
// import  ThreeGiftboxesWithRibbonAndHeart  from './ThreeGiftboxesWithRibbonAndHeart.js';
import  TwoGifboxesTiedTogether  from './TwoGifboxesTiedTogether.js';
import  TwoGifboxesWrapped  from './TwoGifboxesWrapped.js';
import  TwoGiftboxes  from './TwoGiftboxes.js';
import  ValentinesGiftbox  from './ValentinesGiftbox.js';

interface Icons {
  [index: string]: Function
}

const icons: Icons = {
  AniversaryGiftbox,
  // BigGiftboxAndGiftWithHeart,
  BigGiftboxWithBun,
  BigGiftboxWithLateralLace,
  BigGiftboxWithRibbon,
  CylindricalGiftboxWithRibbon,
  GifboxWithLace,
  GifboxWithRibbonInTheMiddle,
  GifboxWithRibbonOnTop,
  GifboxWrappedWithRibbon,
  GiftWithBow,
  GiftWithRibbon,
  GiftboxSide,
  GiftboxWithABigRibbonOnCover,
  GiftboxWithAHeart,
  GiftboxWithAHeartOnSide,
  GiftboxWithBigLace,
  GiftboxWithBigLace1,
  GiftboxWithBigRibbon,
  GiftboxWithBigRibbon1,
  GiftboxWithBigRibbon2,
  GiftboxWithBigRibbon3,
  GiftboxWithBun,
  GiftboxWithFlower,
  GiftboxWithHearts,
  GiftboxWithLaceOnASide,
  GiftboxWithLongRibbon,
  GiftboxWithRibbon,
  GiftboxWithRibbonOnOneSide,
  GiftboxWithRibbonOnTop,
  GiftboxWithRibbonOnTop1,
  GiftboxWrapped,
  HeartShapeGiftboxWithLace,
  HeartShapeGiftboxWithRibbon,
  HeartShappedGifboxWithRibbon,
  OpenBoxWithTwoHearts,
  OpenGitfboxWithTwoHearts,
  PolkaDotsGiftboxWithLace,
  RectangularGiftboxWithFlower,
  RoundGiftBoxWithLace,
  RoundGiftboxWithFlower,
  SquareGifboxWrapped,
  SquareGifsoftWithBun,
  SquareGiftboxWithBigLace,
  SquareGiftboxWithBigRibbon,
  // ThreeGiftboxesWithRibbonAndHeart,
  TwoGifboxesTiedTogether,
  TwoGifboxesWrapped,
  TwoGiftboxes,
  ValentinesGiftbox    
};

export default icons;