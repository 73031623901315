import React from "react";
import { useUser } from 'context/UserContext';
import Identify from "components/Identify";
import RoomPicker from "components/RoomPicker";

function HomeScene() {
  const { user } = useUser();

  return (
    <div className="HomeScene">
      { !user.id && <Identify/> }
      { user.id && <RoomPicker/> }
    </div>
  );
}

export default HomeScene;