import React from "react";
// import React, { useMemo } from "react";
import { makeStyles } from "@material-ui/core";
// import { Avatar, makeStyles } from "@material-ui/core";
// import UserService from "services/UserService";

const useStyles = makeStyles((theme) => ({
  root: {
    // display: 'flex',
    // justifyContent: 'left',
    // alignItems: 'center',
    // marginRight: '.3em'
    color: theme.palette.primary.main
  },
  avatar: {
    width: '20px',
    height: '20px',
    backgroundColor: theme.palette.primary.main,
    color: '#ffffff',
    fontSize: '.6em',
    marginRight: '.5em',
    '&.yourTurn': {
      backgroundColor: theme.palette.success.main,
    }
  }
}));

interface NameTagProps {
  name?: string;
  firstName?: boolean;
};

function NameTag({ name, firstName }: NameTagProps) {
  const classes = useStyles();

  if (!name) {
    return <></>;
  }

  return (
    <span className={classes.root}>
      {/* <Avatar className={classes.avatar} style={{ backgroundColor: color}}>{tag}</Avatar>  */}
      <span style={{fontWeight: 'bold'}}>{firstName ? name.split(' ').shift()?.toUpperCase() : name.toUpperCase()}</span>
    </span>
  );
}

export default NameTag;