import * as React from "react";

function SvgGiftboxWithLongRibbon(props) {
  return (
    <svg viewBox="0 0 416.428 416.428" width="1em" height="1em" {...props}>
      <path d="M381.215 76.777h-47.697c.43-1.43.661-2.957.661-4.596 0-1.151.056-2.808.12-4.727.513-15.32 1.468-43.837-12.849-58.642C315.793 2.966 308.53 0 299.86 0c-22.32 0-50.588 20.475-61.706 29.277-4.302 3.406-20.977 16.979-29.94 29.503-8.962-12.524-25.638-26.097-29.94-29.503C167.157 20.475 138.888 0 116.568 0c-8.67 0-15.934 2.966-21.589 8.813-14.317 14.805-13.362 43.322-12.85 58.642.064 1.919.12 3.575.12 4.727 0 1.639.231 3.166.661 4.596H35.213c-9.649 0-17.5 7.851-17.5 17.5v43.377c0 9.649 7.851 17.5 17.5 17.5h14.052V339.38c0 7.444 6.056 13.5 13.5 13.5h103.449v55.432c0 1.886.633 3.714 1.783 5.147a7.905 7.905 0 006.197 2.97 8.299 8.299 0 004.146-1.13l29.873-17.317 29.879 17.321a8.293 8.293 0 004.141 1.126c4.475 0 7.98-3.565 7.98-8.117V352.88h103.449c7.444 0 13.5-6.056 13.5-13.5V155.154h14.052c9.649 0 17.5-7.851 17.5-17.5V94.277c.001-9.649-7.85-17.5-17.499-17.5zM249.052 39.793C269.042 24.269 288.035 15 299.86 15c4.574 0 8.008 1.348 10.807 4.241 9.895 10.232 9.015 36.497 8.64 47.712-.068 2.054-.128 3.826-.128 5.229v.043c-.326.355-2.587 2.374-12.669 3.515-8.49.96-67.915.96-76.403 0-9.599-1.086-12.108-2.968-12.607-3.452 1.375-4.071 12.552-17.738 31.552-32.495zM109.919 75.739c-10.083-1.141-12.344-3.159-12.67-3.515v-.043c0-1.402-.06-3.175-.128-5.229-.375-11.215-1.255-37.479 8.64-47.712 2.799-2.892 6.233-4.24 10.807-4.24 11.825 0 30.818 9.269 50.808 24.793 19 14.757 30.177 28.424 31.554 32.494-.5.484-3.009 2.366-12.607 3.452-8.49.96-67.914.96-76.404 0zm-74.706 64.415c-1.355 0-2.5-1.145-2.5-2.5V94.277c0-1.355 1.145-2.5 2.5-2.5h131.001v48.377H35.213zm29.052 197.725V155.154h101.949v182.725H64.265zm170.949 58.415l-22.37-12.968c-1.376-.799-2.977-1.221-4.63-1.221s-3.254.422-4.627 1.219l-22.373 12.97V91.777h54v304.517zm116.949-58.415H250.214V155.154h101.949v182.725zm31.552-200.225c0 1.355-1.145 2.5-2.5 2.5H250.214V91.777h131.001c1.355 0 2.5 1.145 2.5 2.5v43.377z" />
    </svg>
  );
}

export default SvgGiftboxWithLongRibbon;
