import { User } from 'types/User';

const createUser = (): User => {
  const user: User = {
    name: '',
    email: '',
    deliveryInstructions: '',
    disableAudio: false,
  };

  UserService.persistUser(user);
  return user;
}

const UserService = {
  // fetch the user from localStorage or create a new blank one and return it
  getUser(): User {
    const json = localStorage.getItem('user');

    if (!json) {
      return createUser();
    }

    return JSON.parse(json);
  },

  persistUser(user: User) {
    localStorage.setItem('user', JSON.stringify(user));
  },

  getTag(name: string) {
    return name.split(' ').map(e => e.substr(0, 1).toUpperCase()).join('');
  },

  getColor(initials: string) {
    var sum = 0;
    
    for(let i = 0; i < initials.length; i++) {
      sum += initials.charCodeAt(i);
    }
  
    const r: number = ~~(('0.'+Math.sin(sum+1).toString().substr(6)) as unknown as number * 256);
    const g: number = ~~(('0.'+Math.sin(sum+2).toString().substr(6)) as unknown as number * 256);
    const b: number = ~~(('0.'+Math.sin(sum+3).toString().substr(6)) as unknown as number * 256);
  
    var rgb = "rgb("+r+", "+g+", "+b+")";
  
    var hex = "#";
  
    hex += ("00" + r.toString(16)).substr(-2,2).toUpperCase();
    hex += ("00" + g.toString(16)).substr(-2,2).toUpperCase();
    hex += ("00" + b.toString(16)).substr(-2,2).toUpperCase();
  
    return {
       r: r
      ,g: g
      ,b: b
      ,rgb: rgb
      ,hex: hex
    };
  },

  getColorFromName(name: string) {
    return this.getColor(this.getTag(name));
  }
}

export default UserService;