import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { GameContext } from './context/GameConnectionContext';
import { UserProvider } from './context/UserContext';
import { GameSocket } from 'services/Socket/GameSocket';
import { GuestContext } from 'context/GuestContext';
import { GuestSocket } from 'services/Socket/GuestSocket';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { GameProvider } from 'context/GameContext';

require('dotenv').config();

const gameSocket = new GameSocket();
const guestSocket = new GuestSocket();

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Poppins, Arial',
  }
});

ReactDOM.render(
  <GameContext.Provider value={gameSocket}>
    <GuestContext.Provider value={guestSocket}>
      <UserProvider>
        <GameProvider>
          <ThemeProvider theme={theme}>
            <App />
          </ThemeProvider>
        </GameProvider>
      </UserProvider>
    </GuestContext.Provider>
  </GameContext.Provider>
  , document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
