import { TextField } from '@material-ui/core';
import React from 'react';

import { AddGiftProps } from './AddGift';

export function DescribeGift({ present, setPresentProp }: AddGiftProps) {
  return (
    <>
      <h3>1) What gift are you bringing?</h3>
      <p>Describe the gift you brought and are putting into the pile. <strong>This will only be shown when someone picks this gift from the pile.</strong> For example:</p>
      
      <ul>
        <li><em>A small cactus with exceptionally pointy pricklers.</em></li>
        <li><em>Two red foam swords, perfect for an impromptu duel.</em></li>
        <li><em>A stuffed bear with a little red bow.</em></li>
      </ul>

      <TextField
        variant="outlined"
        margin="normal"
        required
        multiline
        fullWidth
        id="unwrapped"
        label="What gift are you bringing?"
        name="unwrapped"
        inputProps={{maxLength: 100}}
        value={present.unwrappedDescription}
        onChange={(e) => setPresentProp('unwrappedDescription', e.target.value)}
      />

      <TextField
        variant="outlined"
        margin="normal"
        required
        multiline
        fullWidth
        id="unwrapped"
        label="Does your gift need an explanation? Add it here!"
        name="unwrapped"
        inputProps={{maxLength: 500}}
        value={present.unwrappedCaption}
        onChange={(e) => setPresentProp('unwrappedCaption', e.target.value)}
      />

    </>
  );
}

export default DescribeGift;