import React, { useMemo, useState } from "react";
import { useUser } from 'context/UserContext';
import { Avatar, Typography, TextField, Button, makeStyles, Container, Grid, Paper } from "@material-ui/core";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { User } from 'types/User';
import { format } from 'date-fns'
const randomWords = require('random-words');

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingBottom: '4em'
  },
  paper: {
    display: 'flex',
    padding: theme.spacing(2),
    marginTop: theme.spacing(4),
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  header: {
    fontSize: '1.5em',
    lineHeight: '1.2em',
    margin: 0
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  existingGame: {
    margin: theme.spacing(.5, 0)
  }
}));

function RoomPicker() {
  const { user, setUser } = useUser();

  const [ roomId, setRoomId ] = useState<string>('');
  const [ showCompleteGames, setShowCompleteGames ] = useState<boolean>(false);
  const classes = useStyles();

  const createNewGame = async () => {
    const roomId = randomWords(4).join('-');

    const response = await fetch(`${process.env[`REACT_APP_SOCKET_SERVER`]}/game/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },  
      body: JSON.stringify({
        roomId, userId: user.id, sessionId: user.sessionId
      })
    });

    const json = await response.json();
    if (!response.ok) {
      alert(json.message);
      return;
    }

    window.location.href = `/game/${json.roomId}`;
  }

  const joinGame = async (roomId: string) => {
    const response = await fetch(`${process.env[`REACT_APP_SOCKET_SERVER`]}/game/find`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },  
      body: JSON.stringify({
        roomId
      })
    });

    const json = await response.json();
    if (!response.ok) {
      alert(json.message);
      return;
    }

    window.location.href = `/game/${json.roomId}`;
  }

  const incompleteGames = useMemo(() => user.games.filter(game => !game.completedAt), [ user.games]);
  const completeGames = useMemo(() => user.games.filter(game => !!game.completedAt), [ user.games]);

  const goToRoom = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    joinGame(roomId);
  }

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <CompareArrowsIcon />
          </Avatar>
          
          <Typography component="h1" variant="h3">
            Hi, {user.name}!
          </Typography>
          
          <p>Welcome! Let's get the fun started! Either you're here to join a game that someone else created, or here to start a new one yourself. Look at the options below to get started!</p>
          <p><strong>Hint!</strong> The easiest way to join a game is to use the link you were sent.</p>
        </Paper>

        { (!!incompleteGames.length || !!completeGames.length) && 
            <Paper className={classes.paper}>
              <h4 className={classes.header}>You're already in some games!</h4>
              <p>You've already joined game(s)! Would you like to hop into one of them now?</p>

              <Grid container>
                { incompleteGames.map((game, index) => {
                  return (
                    <Grid key={index} item xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color={game.startedAt? 'secondary' : 'primary'}
                        className={classes.existingGame}
                        onClick={() => joinGame(game.slug)}
                      >
                        <strong>{ game.name || 'Untitled game'}</strong>
                        { game.owner ? `, created by ${game.owner.name}` : ''} 
                        { game.startedAt ? ', in progress!' : (game.scheduledStart ? `, starting at ${format(new Date(game.scheduledStart), 'MM/dd h:mma')}` : ', not started yet')}

                      </Button>
                    </Grid>
                  );
                })}

                { showCompleteGames && completeGames.map((game, index) => {
                  return (
                    <Grid item key={index} xs={12}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="default"
                        className={classes.existingGame}
                        onClick={() => joinGame(game.slug)}
                      >
                        <strong>{ game.name || 'Untitled game'}</strong> 
                        { `, finished ${format(new Date(game.completedAt as Date), 'MM/dd h:mma')}` }
                      </Button>
                    </Grid>
                  );
                })}
              </Grid>

              { !!completeGames.length && 
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  className={classes.submit}
                  onClick={() => setShowCompleteGames(!showCompleteGames)}
                >
                  { showCompleteGames ? 'Hide' : 'Show' } Complete Games
                </Button>
              }
            </Paper>
          }
        
        <Paper className={classes.paper}>
          <h4 className={classes.header}>Trying to join a game that's already created?</h4>
          <p>Enter the "room name" you were given or click the direct link that you might have also gotten.</p>
          <form className={classes.form} onSubmit={goToRoom} noValidate>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="name"
              label="Name"
              name="name"
              autoComplete="name"
              value={roomId}
              onChange={(e) => setRoomId(e.target.value)}
              autoFocus
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
            >
              Join this Room
            </Button>
          </form>
        </Paper>
        
        <Paper className={classes.paper}>
          <h4 className={classes.header}>Here to start a new game for friends or family?</h4>
          <p>Click the button below and we'll get you started right away as a game moderator!</p>

          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            onClick={createNewGame}
          >
            Create a new Game
          </Button>
        </Paper>
        
        <Paper className={classes.paper}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            className={classes.submit}
            onClick={() => setUser({} as User)}
          >
            Logout
          </Button>
        </Paper>
      </div>
    </Container>
  );
}

export default RoomPicker;