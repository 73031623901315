import * as React from "react";

function SvgGiftboxWrapped(props) {
  return (
    <svg viewBox="0 0 377.798 377.798" width="1em" height="1em" {...props}>
      <path d="M330.348 115.073h-34.232c11.486-8.011 17.037-17.842 15.624-27.707-2.234-15.588-21.836-26.061-48.778-26.061-5.124 0-10.389.382-15.649 1.136a68.194 68.194 0 00-9.902 2.191c.494-2.741.776-5.544.776-8.391 0-32.062-21.49-56.24-49.987-56.24s-49.987 24.178-49.987 56.24c0 2.701.262 5.364.719 7.975a67.522 67.522 0 00-8.445-1.775 110.846 110.846 0 00-15.649-1.136c-26.942 0-46.544 10.473-48.778 26.061-1.414 9.864 4.137 19.696 15.624 27.707H47.45c-9.649 0-17.5 7.851-17.5 17.5V360.298c0 9.649 7.851 17.5 17.5 17.5h282.898c9.649 0 17.5-7.851 17.5-17.5V132.573c0-9.65-7.85-17.5-17.5-17.5zM249.44 77.29a95.708 95.708 0 0113.521-.984c20.055 0 33 6.706 33.93 13.189.556 3.88-2.993 8.88-9.494 13.377-8.1 5.602-19.989 9.853-32.62 11.663a54.33 54.33 0 01-7.672.539c-13.653 0-25.814-4.86-33.209-9.145a107.983 107.983 0 004.139-4.462c3.628-4.156 8.438-10.342 12.45-17.789 5.6-2.995 11.993-5.391 18.955-6.388zm-81.54 52.783h41.997v81.958H167.9v-81.958zM188.198 15c20.273 0 34.987 17.344 34.987 41.24 0 23.903-27.576 47.98-34.893 51.042-7.378-3.311-35.082-27.857-35.082-51.042.001-23.896 14.715-41.24 34.988-41.24zm-65.177 99.534c-12.631-1.81-24.521-6.061-32.62-11.663-6.5-4.497-10.05-9.497-9.494-13.377.929-6.483 13.875-13.189 33.93-13.189 4.415 0 8.964.331 13.521.984 6.249.896 12.032 2.924 17.201 5.492 4.161 7.844 9.282 14.372 13.105 18.71a113.177 113.177 0 004.535 4.836c-7.449 4.182-19.277 8.744-32.506 8.744a53.857 53.857 0 01-7.672-.537zM44.95 132.573c0-1.355 1.145-2.5 2.5-2.5H152.9v81.958H44.95v-79.458zM152.9 362.798H47.45c-1.355 0-2.5-1.145-2.5-2.5v-76.27H152.9v78.77zm56.997-1H167.9v-77.77h41.997v77.77zm122.951-1.5c0 1.355-1.145 2.5-2.5 2.5H224.897v-78.77h107.951v76.27zm0-91.27H44.95v-41.997h287.898v41.997zm0-56.997H224.897v-81.958h105.451c1.355 0 2.5 1.145 2.5 2.5v79.458z" />
    </svg>
  );
}

export default SvgGiftboxWrapped;
