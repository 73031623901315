// @todo unused at the moment, this is pretty not working
import React, { useMemo } from "react";
import { Avatar, Chip, makeStyles } from "@material-ui/core";
import { useGame } from "context/GameContext";
import { useUser } from "context/UserContext";

import clsx from 'clsx';
import UserService from "services/UserService";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '.5em',
    [theme.breakpoints.down('xs')]: {
      padding: '0'
    }
  },
  historyWrapper: {
    maxHeight: '90vh',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '160px'
    }
  },
  chip: {
    width: '100%',
    marginBottom: '.2em',
    justifyContent: 'left',
    height: 'auto',
    minHeight: '32px',
    borderRadius: '6px',
    backgroundColor: 'white',
    '&.active': {
      backgroundColor: theme.palette.success.light
    }
  },
  chipLabel: {
    color: 'black',
    whiteSpace: 'pre-wrap',
    paddingTop: '4px',
    paddingBottom: '4px'
  },
  activeAvatar: {
    backgroundColor: theme.palette.success.dark,
    color: 'white !important'
  }
}));

function GameStatus() {
  const classes = useStyles();
  const { game, activeRound, activeRounds, activePlayer } = useGame();
  const { user } = useUser();

  const myRound = useMemo(() => {
    const myRound = game.rounds.find(e => e.playerId === user.id);
    return myRound ? myRound.sort : 0;
  }, [ game.rounds, user.id ]);

  const lastActions = useMemo(() => {
    if (!activeRounds) {
      return [];
    }

    const actions = activeRounds
      .map(round => {
        return round.actions.map((action) => { 
          return { ...action, roundNumber: round.sort }; 
        })
      }).flat()
      .map(action => {
        return {
          round: action.roundNumber,
          tag: UserService.getTag(action.player?.name as string),
          label: `${action.player?.name} took ${action.present?.unwrappedDescription ?? 'a present'} from ${action.victim?.name ?? 'the pile'}`
        };
      });

      setTimeout(() => {
        const historyWrapper = document.querySelector('#history-wrapper') as HTMLElement;
        if (historyWrapper) {
          historyWrapper.scrollTop = historyWrapper.scrollHeight;
        }  
      }, 500)
  
    return actions;
  }, [ activeRounds ]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!game?.rounds) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <div id="history-wrapper" className={classes.historyWrapper}>
        { lastActions.map((action, index) => {
          return (
            <Chip
              key={index}
              classes={{root: classes.chip, label: classes.chipLabel}}
              avatar={<Avatar>{action.round}</Avatar>}
              label={action.label}
              variant="outlined"
            />
          );
        })}

        { !!activePlayer && activePlayer.id !== user.id && <Chip
          classes={{root: classes.chip, label: classes.chipLabel}}
          avatar={<Avatar>{UserService.getTag(activePlayer.name as string)}</Avatar>}
          label={`${activePlayer.name} is up, they're thinking about what to do...`}
          variant="outlined"
          color="primary"
        /> }

        { !!activePlayer && activePlayer.id === user.id && <Chip
          classes={{root: clsx('active', classes.chip), avatar: classes.activeAvatar, label: classes.chipLabel}}
          avatar={<Avatar>{UserService.getTag(user.name as string)}</Avatar>}
          label={`You're up! Steal a gift that isn't locked or take a new one from the pile to end the round!`}
        /> }

        { myRound > (activeRound ? activeRound.sort : 50000) && <Chip
          classes={{root: classes.chip, avatar: classes.activeAvatar, label: classes.chipLabel}}
          avatar={<Avatar>{UserService.getTag(user.name as string)}</Avatar>}
          variant="outlined"
          label={`You're up in round ${myRound}! Be patient!`}
        /> }
      </div>

      
    </div>
  );
}

export default GameStatus;