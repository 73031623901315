import React, { useEffect, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import { useGame } from 'context/GameContext';
import { makeStyles } from '@material-ui/core';

interface ReconnectDialogProps {
  room: string;
}

const useStyles = makeStyles((theme) => ({
  dialogWrapper: {
    padding: '2em'
  },
}));

export default function ReconnectDialog({ room }: ReconnectDialogProps) {
  const classes = useStyles();
  const [ reconnecting, setReconnecting ] = useState<boolean>(false);
  const { game, connected, joinRoom } = useGame();

  const disconnected = useMemo((): boolean => {
    return !!game.id && !connected;
  }, [ game, connected ]);

  useEffect(() => {
    if (connected) {
      setReconnecting(false);
    }
  }, [ connected ]);

  if (!disconnected) {
    return <></>;
  }

  const reconnect = () => {
    setReconnecting(true);

    joinRoom('');
    setTimeout(() => {
      joinRoom(room);
    }, 200)
  };


  return (
    <Dialog aria-labelledby="simple-dialog-title" open={disconnected}>
      <div className={classes.dialogWrapper}>
        <DialogTitle id="simple-dialog-title">You're Disconnected</DialogTitle>
        <p>Uh oh! Use the button below to try and reconnect...</p>
        <Button disabled={reconnecting} fullWidth variant="contained" color="primary" onClick={reconnect}>
          { reconnecting ? 'Reconnecting' : 'Reconnect' }
        </Button>
        </div>
    </Dialog>
  );
}