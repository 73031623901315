import * as React from "react";

function SvgTwoGifboxesTiedTogether(props) {
  return (
    <svg viewBox="0 0 364.795 364.795" width="1em" height="1em" {...props}>
      <path d="M335.269 201.094H305.53v-73.637h2.839c9.649 0 17.5-7.851 17.5-17.5V84.85c0-9.649-7.851-17.5-17.5-17.5h-16.323c.309-1.197.483-2.462.483-3.813 0-.98.048-2.408.104-4.062.449-13.421 1.285-38.402-11.413-51.533C276.123 2.672 269.588 0 261.795 0c-18.944 0-42.015 16.027-54.081 25.581-3.627 2.872-17.283 13.993-25.316 24.717-8.033-10.724-21.689-21.844-25.316-24.717C145.015 16.027 121.944 0 103 0c-7.793 0-14.328 2.672-19.425 7.941-12.698 13.131-11.862 38.113-11.413 51.534.056 1.653.104 3.081.104 4.062 0 1.351.174 2.616.483 3.813H56.426c-9.649 0-17.5 7.851-17.5 17.5v25.107c0 9.649 7.851 17.5 17.5 17.5h2.839v73.637H29.526c-9.649 0-17.5 7.851-17.5 17.5v25.107c0 9.649 7.851 17.5 17.5 17.5h2.994v90.094c0 7.444 6.056 13.5 13.5 13.5h272.754c7.444 0 13.5-6.056 13.5-13.5v-90.094h2.994c9.649 0 17.5-7.851 17.5-17.5v-25.107c.001-9.65-7.85-17.5-17.499-17.5zm-44.739 0H189.897v-73.637H290.53v73.637zM310.869 84.85v25.107c0 1.355-1.145 2.5-2.5 2.5H189.897V82.35h118.472c1.356 0 2.5 1.144 2.5 2.5zm-92.145-48.838C235.745 22.854 251.846 15 261.795 15c3.673 0 6.419 1.07 8.642 3.369 8.277 8.559 7.525 31.015 7.204 40.604-.053 1.583-.1 2.976-.109 4.131-.749.514-84.805.78-85.85.183 1.96-3.975 10.97-14.851 27.042-27.275zM94.358 18.369C96.581 16.07 99.327 15 103 15c9.949 0 26.05 7.854 43.071 21.012 16.071 12.424 25.082 23.3 27.042 27.275-1.045.598-85.101.331-85.85-.183-.01-1.153-.057-2.547-.109-4.131-.321-9.589-1.073-32.045 7.204-40.604zm-40.432 91.588V84.85c0-1.355 1.145-2.5 2.5-2.5h118.472v30.107H56.426c-1.356 0-2.5-1.144-2.5-2.5zm20.339 17.5h100.633v73.637H74.265v-73.637zM27.026 243.701v-25.107c0-1.355 1.145-2.5 2.5-2.5H174.897v30.107H29.526c-1.355 0-2.5-1.144-2.5-2.5zm20.495 17.5h127.377v88.594H47.521v-88.594zm269.753 88.594H189.897v-88.594h127.377v88.594zm20.495-106.094c0 1.355-1.145 2.5-2.5 2.5H189.897v-30.107H335.268c1.355 0 2.5 1.145 2.5 2.5v25.107z" />
    </svg>
  );
}

export default SvgTwoGifboxesTiedTogether;
