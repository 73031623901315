// @todo unused at the moment, this is pretty not working
import React, { useMemo, useState } from "react";
import { Accordion, AccordionDetails, AccordionSummary, makeStyles } from "@material-ui/core";
import { useGame } from "context/GameContext";
import { format } from 'date-fns'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    marginBottom: '10px !important'
  },
  accordionSummary: {
    margin: '0 !important',
    minHeight: 'auto !important'
  }
}));

function GameInfo() {
  const classes = useStyles();
  const { game } = useGame();
  const [ expanded, setExpanded ] = useState<boolean>(!game.startedAt);

  const connectedPlayers = useMemo(() => {
    if (!game.players) {
      return 0;
    }

    return game.players.filter(e => e.connected !== false).length;
  }, [ game ]);

  const players = useMemo(() => {
    if (!game.players) {
      return 0;
    }

    return game.players.length;
  }, [ game ]);

  const presents = useMemo(() => {
    if (!game.presents) {
      return 0;
    }

    return game.presents.length;
  }, [ game ])


  return (
    <div>
      <Accordion 
        expanded={expanded} 
        onChange={() => setExpanded(!expanded)} 
        classes={{
          root: classes.accordionRoot,
          expanded: classes.accordionRoot
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          classes={{
            root: classes.accordionSummary,
            expanded: classes.accordionSummary,
            content: classes.accordionSummary
          }}
        >
          { !game.startedAt && <h3>Welcome to Virtual Pollyanna!</h3> }
          { game.startedAt && <h3>Pollyanna Rules and Info</h3> }
          
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <p>And welcome to the game! This info box will go over some rules and game information. You can collapse it and open it back up whenever you need to to get a refresher on the rules of the game.</p>
            
            <h4>General Pollyanna Rules</h4>
            <ul>
              <li>When the game starts, you will be assigned a number. That is the round that you'll make your first move.</li>
              <li>When it's your round, you can choose a gift! Either pull from the pile of unopened gifts or steal an available gift from someone else!</li>
              { !!game.roughPricePoint && <li>Gifts should be around ${game.roughPricePoint}.</li>}
              { game.stealsPerGiftPerRound === 1 && <li>Any gift can only be stolen once per round. After it's stolen, it's safe until the next round.</li>}
              { game.stealsPerGiftPerRound > 1 && <li>Each gift can be stolen <strong>{ game.stealsPerGiftPerRound } times per round</strong>, and cannot be stolen back immediately.</li>}
              { game.stealsPerGiftPerRound === 0 && <li><strong>Gifts can be stolen an unlimited number of times per round! Prepare for chaos!</strong> Also, no steal backs.</li>}
              <li>If someone steals a gift from you, you're back up!</li>
              <li>Each round ends when an unopened gift is pulled from the pile.</li>
            </ul>

            { !game.startedAt && 
              <>
                <h4>Game information</h4>
                <ul>
                  { game.scheduledStart && <li>This game is scheduled to start on <strong>{ format(new Date(game.scheduledStart), 'MM/dd h:mma')}</strong>. Make sure to add your gift before then!</li> }
                  { game.voiceConferenceInfo && <li>In addition to game chat, you can also join the party remotely! <strong>{ game.voiceConferenceInfo }</strong></li> }
                  <li>
                    So far, this game has { players } players.
                    <ul>
                      <li> { connectedPlayers } are currently connected.</li>
                      <li> { presents } have added their presents to the pile.</li>
                    </ul>
                  </li>
                </ul>
              </>
            }
          </div>
        </AccordionDetails>
      </Accordion>
    </div>
  );
}

export default GameInfo;