import { Grid, makeStyles } from '@material-ui/core';
import React from 'react';
import svgs from 'icons';
import { AddGiftProps } from './AddGift';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: '.5em'
  },
  giftIcon: {
    padding: '5px',
    height: '40px',
    width: '100%',
    '&.selected': {
      border: `2px solid ${theme.palette.primary.main}`,
      borderRadius: '5px'
    }
  }
}));

export function PickIcon({ present, setPresentProp}: AddGiftProps) {
  const classes = useStyles();
  
  return (
    <>
      <h3>Pick an icon and put it in the pile!</h3>
      
      <Grid className={classes.container} container spacing={1}>
        { Object.entries(svgs).map(([index, Component]) => {   
          return (
            <Grid key={index} item xs={2}>
              <Component 
                onClick={() => setPresentProp('wrappedIcon', index)} 
                className={clsx(classes.giftIcon, { selected: index === present.wrappedIcon })}
              />
            </Grid>
          );
        }) }

      </Grid>
    </>
  );
}

export default PickIcon;