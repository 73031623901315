import * as React from "react";

function SvgGifboxWithRibbonOnTop(props) {
  return (
    <svg viewBox="0 0 382.878 382.878" width="1em" height="1em" {...props}>
      <path d="M364.44 76.776h-47.709c.488-1.574.673-3.138.673-4.595 0-1.151.056-2.808.12-4.726.513-15.32 1.468-43.837-12.849-58.641C299.019 2.965 291.755 0 283.085 0c-22.32 0-50.588 20.475-61.707 29.277-4.302 3.406-20.977 16.979-29.939 29.503-8.962-12.524-25.638-26.096-29.94-29.503C150.381 20.475 122.113 0 99.793 0c-8.67 0-15.934 2.965-21.589 8.814-14.317 14.804-13.363 43.321-12.85 58.641.064 1.918.12 3.575.12 4.726a15.5 15.5 0 00.673 4.595H18.438c-9.649 0-17.5 7.851-17.5 17.5v73.376c0 9.649 7.851 17.5 17.5 17.5H32.49v185.225c0 6.893 5.607 12.5 12.5 12.5h292.898c6.893 0 12.5-5.607 12.5-12.5V185.153h14.052c9.649 0 17.5-7.851 17.5-17.5V94.276c0-9.649-7.851-17.5-17.5-17.5zm-154.742 93.377H173.18V92.215h36.518v77.938zm22.579-130.36C252.267 24.269 271.26 15 283.085 15c4.574 0 8.008 1.348 10.807 4.242 9.895 10.231 9.015 36.496 8.64 47.711-.068 2.034-.127 3.793-.128 5.189-.432.377-85.718 3.559-92.432 2.678-6.486-.851-8.641-2.147-9.223-2.606 1.481-4.156 12.638-17.751 31.528-32.421zM88.986 19.242C91.785 16.348 95.219 15 99.793 15c11.825 0 30.818 9.269 50.808 24.793 18.89 14.671 30.047 28.265 31.529 32.422-.582.459-2.737 1.755-9.224 2.606-6.714.88-92-2.301-92.432-2.678-.001-1.396-.06-3.155-.128-5.189-.375-11.216-1.255-37.481 8.64-47.712zM15.938 167.653V94.276c0-1.355 1.145-2.5 2.5-2.5H158.18v78.376H18.438c-1.355.001-2.5-1.144-2.5-2.499zM47.49 367.878V185.153h110.69v182.725H47.49zm125.69 0V185.153h36.518v182.725H173.18zm162.208 0h-110.69V185.153h110.69v182.725zm31.552-200.225c0 1.355-1.145 2.5-2.5 2.5H224.698V91.776H364.44c1.355 0 2.5 1.145 2.5 2.5v73.377z" />
    </svg>
  );
}

export default SvgGifboxWithRibbonOnTop;
