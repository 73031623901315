import React from "react";

import { Container, makeStyles, Paper, Theme } from "@material-ui/core";
import { useGame } from "context/GameContext";

const useStyles = makeStyles(({ breakpoints}: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: '2em',
    marginTop: '5em'
  }
}));

function GameError() {
  const { error } = useGame();
  const classes = useStyles();

  if (!error) {
    return <></>;
  }

  return (
    <Container maxWidth="sm">
      <div className={classes.root}>
        <Paper elevation={3} className={classes.paper}>
          <h3>Oh no, there was a problem!</h3>
          <p>Unfortunately, we ran into a problem putting you in this game:</p>
          <p><strong>{ error }</strong></p>
          <p>Here's what you can do to try and fix this:</p>
          <ul>
            <li>Games in progress cannot be joined by players that weren't in the game when it started.</li>
            <li><a href="/">Go back home</a> and try accessing the game again</li>
            <li>Log out and back in. Make sure you're using the right email and password!</li>
          </ul>

          
          
        </Paper> 
      </div>
    </Container>
  );
}

export default GameError;