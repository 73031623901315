import React, { createContext, useContext, useState } from 'react';
import UserService from 'services/UserService';
import { SessionUser, User } from 'types/User';
import { Game } from 'types/Game';

type SetUser = (user: User) => void;
type UpdateUserGame = (game: Game) => void;
type ToggleAudio = () => void;

type UserContext = {
  user: SessionUser;
  setUser: SetUser;
  updateUserGame: UpdateUserGame;
  toggleAudio: ToggleAudio;
}

const UserStateContext = createContext<UserContext>({} as UserContext);

export function UserProvider({ children }: { children: React.ReactNode}) {
  const sessionUser = UserService.getUser();

  const [user, setUserState] = useState<SessionUser>(new SessionUser(sessionUser));

  const setUser = (user: User): void => {
    UserService.persistUser(user);
    setUserState(new SessionUser(user));
  }

  const updateUserGame = (game: Game): void => {
    const index = user.games.findIndex(g => g.id === game.id);
    const update = Object.assign({}, user);

    if (index < 0) {
      update.games.push(game);
    } else {
      update.games[index] = game;
    }

    UserService.persistUser(update);
    setUserState(update);
  }

  const toggleAudio = (): void => {
    const update = Object.assign({}, user);
    update.disableAudio = !update.disableAudio;
    
    UserService.persistUser(update);
    setUserState(update);
  }


  return (
    <UserStateContext.Provider value={{user, setUser, updateUserGame, toggleAudio}}>
      { children }
    </UserStateContext.Provider>
  )
}

export function useUser(): UserContext {
  const userStateContext = useContext(UserStateContext);

  if (userStateContext === undefined) {
    throw new Error('useUser must be used inside a UserProvider');
  }

  return userStateContext;
}