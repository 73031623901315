
import React, { useMemo, useState } from 'react';
import clsx from 'clsx';

import { Dialog, DialogActions, DialogContent, Grid, makeStyles, Paper } from '@material-ui/core';
import { useGame } from 'context/GameContext';
import { useUser } from 'context/UserContext';
import { Present } from 'types/Present';
import NameTag from './NameTag';
import { User } from 'types';
import Button from '@material-ui/core/Button/Button';
import svgs from 'icons/index';
import { useGameConnection } from 'context/GameConnectionContext';

type SelectablePresentProps = {
  present: Present
};

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0.5em',
    minHeight: '140px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  active: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.info.light
    }
  },
  unwrappedIcon: {
    width: '33%',
    height: '50px'
  },
  lockedGift: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgb(50, 50, 50, 0.2)',
    opacity: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lockedGiftIcon: {
    width: '3em', 
    height: '3em', 
    color: 'rgb(0,0,0, .4)',
  },
  giftHolder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  wrappedDescription: {
    textAlign: 'center'
  },
  unwrappedCaption: {
    fontStyle: 'italic'
  },
  unwrappedImageWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  expandedImage: {
    width: '100%',
  },
  expandedIcon: {
    width: '100%',
    height: '140px'
  },
  overlayName: {
    position: 'absolute',
    bottom: '0.5em',
    left: '0.5em',
    padding: '.25em',
    borderRadius: '0.25em',
    background: 'rgb(255,255,255,0.9)'
  },
  overlaySteals: {
    position: 'absolute',
    top: '0.5em',
    left: '0.5em',
    padding: '.25em',
    borderRadius: '0.25em',
    background: 'rgb(255,255,255,0.9)'
  },
  header: {
    background: 'white',
    padding: '5px',
    borderRadius: '5px'
  }
}));

export function SelectablePresent({present}: SelectablePresentProps) {
  const classes = useStyles();
  const { game, activePlayer, activeRound } = useGame();
  const { user } = useUser();
  const [ expanded, setExpanded ] = useState<boolean>(false);
  const context = useGameConnection();

  const isUserActive = useMemo(() => activePlayer?.id === user?.id, [ user, activePlayer ]);
  const isUserAdmin = useMemo(() => game?.startedAt && !game?.completedAt && game?.ownerId === user?.id, [ user, game?.completedAt, game?.startedAt, game?.ownerId ]);
  const currentHolder = useMemo(() => game.players.find(e => e.id === present.holderId), [ game.players, present.holderId ]);
  const Icon = present.wrappedIcon ? svgs[present.wrappedIcon] : svgs[Object.keys(svgs).shift() as string];

  const selectPresent = () => {
    if (!isUserActive && !isUserAdmin) {
      return;
    }

    context.pickPresent(present.id as string);
    setExpanded(false);
  }

  const stealsRemaining: number = useMemo(() => {
    const steals = activeRound?.actions?.filter((action) => action.present.id === present.id).length || 0;
    const remaining = game.stealsPerGiftPerRound - steals;
    return (remaining > 0 && steals) ? remaining : 0;
  }, [ activeRound, activePlayer, game.stealsPerGiftPerRound, present.id ]); // eslint-disable-line react-hooks/exhaustive-deps


  return (
    <>
      <Grid item xs={6} sm={4} md={3}>
        <Paper 
          onClick={() => setExpanded(true)} elevation={3} 
          className={clsx(classes.paper, {[classes.active]: isUserActive})}
        >
          { !present.holderId && 
            <>
              <Icon className={classes.unwrappedIcon} alt="unknown gift" />
              <div className={classes.wrappedDescription}>{ present.wrappedDescription }</div>
            </>
          }

          { !!present.holderId && 
            <>
              <p>{ present.unwrappedDescription }</p>

              { !!present.imageName && 
                <div className={classes.unwrappedImageWrapper} style={{backgroundImage: `url(${process.env.REACT_APP_API_SERVER}/static/${present.imageName})`}} />
              }

              <div className={classes.overlayName}>
                <NameTag firstName={true} name={(game.players.find(e => e.id === present.holderId) as User).name}/> 
              </div>

              { stealsRemaining > 0 && 
                <div className={classes.overlaySteals}>
                  { stealsRemaining } { stealsRemaining > 1 ? 'steals' : 'steal' } left! 
                </div> 
              }
            </>
          }
        </Paper>
      </Grid>

      <Dialog
        open={expanded}
        onClose={() => setExpanded(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
      <DialogContent>
        { !present.holderId && 
          <div>
            <Icon className={classes.expandedIcon} alt="unknown gift" />
            <div className={classes.wrappedDescription}>{ present.wrappedDescription }</div>
          </div>
        }

        { !!present.holderId && 
          <>
            { !!present.imageName && 
              <img alt={present.unwrappedDescription} className={classes.expandedImage} src={`${process.env.REACT_APP_API_SERVER}/static/${present.imageName}`} />
            }

            <p>{ present.unwrappedDescription }</p>
            <p className={classes.unwrappedCaption}>{ present.unwrappedCaption }</p>

            { currentHolder && 
              <p>Currently held by {currentHolder.name}</p>
            }
          </>
        }

      </DialogContent>
      <DialogActions>

        { isUserAdmin && !isUserActive && <>
          <Button onClick={selectPresent} color="secondary" autoFocus variant={'contained'}>
            Take for { activePlayer?.name }
          </Button>
        </> }

        { isUserActive && <>
          <Button onClick={() => setExpanded(false)} color="secondary" variant={'contained'}>
            Don't Take
          </Button>
          <Button onClick={selectPresent} color="primary" autoFocus variant={'contained'}>
            { currentHolder ? 'Steal!' : 'Take!' }
          </Button>
        </> }

        { !isUserActive && <>
          <Button onClick={() => setExpanded(false)} color="primary">
            Close
          </Button>
        </> }
      </DialogActions>
      </Dialog>
    </>
  )
}