import React, { useEffect, useState } from "react";
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { Button, makeStyles, Paper, Slider, TextField, Typography } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';

import clsx from 'clsx';

import { useGameConnection } from 'context/GameConnectionContext';
import { useGame } from "context/GameContext";

const useStyles = makeStyles((theme) => ({
  admin: {
    backgroundColor: '#ecf6fe'
  },
  paper: {
    padding: '1em',
    marginBottom: '2em'
  },
  form: {
    marginBottom: '1em'
  },
  dateWrapper: {

  },
  sliderWrapper: {
    padding: '0 4em'
  }
}));

function GameAdmin() {
  const context = useGameConnection();
  const { game } = useGame();
  const classes = useStyles();

  const [ name, setName ] = useState<string>(game.name);
  const [ scheduledStart, setScheduledStart ] = useState<Date | undefined | null>(game.scheduledStart);
  const [ voiceConferenceInfo, setVoiceConferenceInfo ] = useState<string | undefined | null>(game.voiceConferenceInfo || '');
  const [ slug, setSlug ] = useState<string>(game.slug);
  const [ stealsPerGiftPerRound, setStealsPerGiftPerRound ] = useState<number>(game.stealsPerGiftPerRound);
  const [ roughPricePoint, setRoughPricePoint ] = useState<number>(game.roughPricePoint);

  const formatPrice = (price: number) => {
    return price ? `$${price}` : 'Unltd'
  }
  
  const stealMarks = [
    {
      value: 0,
      label: 'Unltd',
    },
    ...Array(10).fill(null).map((e, i) => { return { value: i+1, label: `${i+1}` }}),
  ];

  const priceMarks = [ 0, 25, 50, 100, 250 ]
    .map((v) => {
      return {
        value: v,
        label: formatPrice(v),
      }
    });
  
  useEffect(() => {
    setName(game.name);
    setSlug(game.slug);
    setStealsPerGiftPerRound(game.stealsPerGiftPerRound);
  }, [game]);

  const saveGame = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    context.updateGame({ name, slug, scheduledStart, voiceConferenceInfo, stealsPerGiftPerRound, roughPricePoint });
  }

  const gameIsReady = () => {
    return game.players.length === game.presents.length;
  }

  if (!game.id || game.startedAt) {
    return <></>;
  }

  return (
    <Paper elevation={3} className={clsx(classes.admin, classes.paper)}>
      <h3>Alright, let's get started!</h3>

      <p>This panel will go away once the game starts (it will be replaced by another to help you move things along if someone gets stuck). But once you start the game, it cannot be unstarted. If you want to change any of the game information, do so below:</p>
      
      <form onSubmit={saveGame} className={classes.form} noValidate>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          id="name"
          label="Game Name"
          name="name"
          autoComplete="name"
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="slug"
          label="URL of game"
          id="slug"
          autoComplete="slug"
          value={slug}
          onChange={(e) => setSlug(e.target.value)}
        />

        <Typography id="discrete-slider-custom" gutterBottom>
          How many times can a gift be stolen per round?
        </Typography>
        <div className={classes.sliderWrapper}>
          <Slider
            value={stealsPerGiftPerRound}
            getAriaValueText={(value: number) => `${value} steals` }
            aria-labelledby="discrete-slider-custom"
            step={1}
            min={0}
            max={10}
            valueLabelDisplay="auto"
            marks={stealMarks}
            onChange={(event: any, newValue: number | number[]) => {
              setStealsPerGiftPerRound(newValue as number);
            }}
          />
        </div>

        <Typography id="discrete-slider-custom" gutterBottom>
          Roughly what price should gifts be?
        </Typography>
        <div className={classes.sliderWrapper}>
          <Slider
            value={roughPricePoint}
            getAriaValueText={(value: number) => `$${value}` }
            aria-labelledby="discrete-slider-custom"
            step={null}
            min={-10}
            max={250}
            valueLabelDisplay="auto"
            valueLabelFormat={formatPrice}
            marks={priceMarks}
            onChange={(event: any, newValue: number | number[]) => {
              setRoughPricePoint(newValue as number);
            }}
          />
        </div>


        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DateTimePicker 
            inputVariant="outlined"
            value={scheduledStart} 
            emptyLabel={"No scheduled start date"}
            label={"Scheduled Start"}
            fullWidth
            margin={"normal"}
            onChange={setScheduledStart} />
        </MuiPickersUtilsProvider>

        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          multiline
          name="conferenceCallInfo"
          label="Remote Call Information (add a zoom link, skype info, whatever you want)"
          id="conferenceCallInfo"
          autoComplete="conferenceCallInfo"
          value={voiceConferenceInfo}
          onChange={(e) => setVoiceConferenceInfo(e.target.value)}
        />

        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
        >
          Update Game Information
        </Button>
      </form>

      <p>If you're ready to start (check the player list) and everyone has added either photos or details of their gifts, you can start the game. <strong>Be advised!</strong> Once you start a game, it cannot be unstarted. Progress can only move forward.</p>

      <Button
        type="button"
        fullWidth
        variant="contained"
        color="secondary"
        onClick={() => context.startGame()}
        disabled={!gameIsReady()}
        style={{marginBottom: '1em'}}
      >
        Start this game!
      </Button>

      {!gameIsReady() &&
        <span style={{fontStyle: 'italic' }}>There are { game.presents.length } gifts saved and ready, and { game.players.length } players. Once everyone submits a present you can start the game.</span>
      }
    </Paper> 
  );
}

export default GameAdmin;