import io from 'socket.io-client';
import config from 'config';
import { SessionUser, User } from 'types/User';
import { UserEvent } from 'socket/events';

const { v4: uuidv4 } = require('uuid');

export class SocketService {
  protected socket: SocketIOClient.Socket = {} as SocketIOClient.Socket;

  // expose listener
  public getSocket = () => this.socket;
  public connected = () => {
    return !!this.socket.connected
  };
  
  // initiate the connection, provide a namespace to connect to a specific area
  protected init(namespace: string | null = null): SocketService {
    console.debug('[SocketService](init)');
    const url = config.socketServer() + (namespace ? namespace : '');
    this.socket = io(url);

    return this;
  }

  // identify oneself with the application
  public identify(data: User): User {
    console.debug('[SocketService](identify)', data);

    const user = new SessionUser(data);
    user.id = user.id ? user.id : uuidv4();

    this.socket.emit(UserEvent.IDENTIFY, {id: user.id, sessionId: user.sessionId });
    return user;
  }

  // disconnect - used when unmounting
  public disconnect(): void {
    console.debug('[SocketService](disconnect)');
    this.socket.disconnect();
  }
}
