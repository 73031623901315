import * as React from "react";

function SvgBigGiftboxWithLateralLace(props) {
  return (
    <svg viewBox="0 0 425.437 425.437" width="1em" height="1em" {...props}>
      <path d="M407.937 41.997H17.5c-9.649 0-17.5 7.851-17.5 17.5v51.071c0 9.649 7.851 17.5 17.5 17.5h17.882V369.94c0 7.444 6.056 13.5 13.5 13.5h327.672c7.444 0 13.5-6.056 13.5-13.5V128.068h17.882c9.649 0 17.5-7.851 17.5-17.5V59.497c.001-9.65-7.85-17.5-17.499-17.5zM375.054 368.44H50.382V128.068h324.672V368.44zm35.383-257.872c0 1.355-1.145 2.5-2.5 2.5H17.5c-1.355 0-2.5-1.145-2.5-2.5V59.497c0-1.355 1.145-2.5 2.5-2.5h390.437c1.355 0 2.5 1.145 2.5 2.5v51.071z" />
      <path d="M168.717 250.436c2.113 3.361 6.567 7.367 15.791 7.367 10.845 0 24.586-2.378 37.647-6.335l-40.954 43.871c-5.52 5.913-4.158 11.057-3.316 12.995.818 1.882 3.528 6.258 11.017 6.259.465 0 .953-.017 1.464-.054l46.47-3.302c8.853-.628 17.024-7.962 18.603-16.696l6.024-33.332 6.024 33.331c1.578 8.734 9.75 16.068 18.602 16.697l46.47 3.302c.511.037.999.054 1.464.054 7.489 0 10.199-4.377 11.017-6.259.842-1.938 2.204-7.082-3.315-12.995l-41.174-44.106c13.294 4.102 27.374 6.571 38.438 6.571 9.223 0 13.678-4.006 15.791-7.367 6.423-10.217-.866-25.37-7.915-40.023-2.121-4.409-4.963-10.319-5.538-12.804.78-1.658 3.155-5.134 4.783-7.518 6.523-9.548 13.917-20.37 9.188-29.328-2.702-5.119-8.382-7.714-16.881-7.714-16.854 0-38.251 10.484-52.655 20.295-5.915 4.029-16.726 12.111-24.013 21.766-7.287-9.655-18.098-17.737-24.013-21.766-14.404-9.812-35.8-20.295-52.655-20.295-8.499 0-14.179 2.595-16.881 7.714-4.729 8.958 2.665 19.78 9.188 29.328 1.629 2.384 4.003 5.859 4.784 7.518-.575 2.485-3.417 8.396-5.539 12.804-7.05 14.652-14.339 29.804-7.916 40.022zm155.841 48.496l-37.407-2.658c-2.062-.146-4.537-2.368-4.904-4.402l-8.576-47.451 50.887 54.511zm-30.353-113.191c15.778-10.748 33.132-17.692 44.21-17.692 1.451 0 2.464.107 3.145.227-1.053 3.423-5.532 9.979-7.837 13.354-4.349 6.365-7.491 10.964-7.491 15.524 0 4.742 2.57 10.317 7.114 19.761 2.617 5.441 5.583 11.608 7.343 16.89 1.95 5.851 1.563 8.053 1.417 8.553-.284.131-1.17.445-3.12.445-28.674 0-69.731-17.136-69.731-29.104.001-5.282 7.808-16.282 24.95-27.958zm-53.528 106.13c-.368 2.034-2.843 4.256-4.905 4.402l-37.406 2.658 50.887-54.511-8.576 47.451zm-57.871-58.067c1.76-5.281 4.727-11.448 7.344-16.889 4.543-9.445 7.114-15.02 7.114-19.762 0-4.56-3.142-9.159-7.491-15.524-2.305-3.374-6.785-9.93-7.837-13.353.681-.12 1.694-.227 3.145-.227 11.079 0 28.432 6.945 44.21 17.692 17.141 11.676 24.948 22.677 24.948 27.957 0 11.968-41.057 29.104-69.731 29.104-1.949 0-2.836-.314-3.12-.445-.146-.5-.533-2.702 1.418-8.553z" />
    </svg>
  );
}

export default SvgBigGiftboxWithLateralLace;
