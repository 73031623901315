import React, { useState } from "react";
import { useGameConnection } from 'context/GameConnectionContext';

import { Button, List, ListItem, ListItemText, makeStyles, TextField } from "@material-ui/core";
import { GameMessage } from "types";
import { useGame } from "context/GameContext";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '25%',
    flexShrink: 0
  },
  drawerPaper: {
    width: '25%',
  },
  chatWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: '84px',
    overflowY: 'auto'
  },
  chatInput: {
    backgroundColor: 'white',
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '0 5px',
    margin: 0
  },
  chatGutters: {
    padding: '1px 12px'
  },
  chatMessage: {
    color: theme.palette.text.primary
  },
  chatPlayer: {
    color: theme.palette.primary.main,
    fontSize: '0.85em',
    textTransform: 'uppercase',
    fontWeight: 'bold'
  }
}));

function GameChat() {
  const context = useGameConnection();
  const socket = context.getSocket();
  const { messages } = useGame();
  const classes = useStyles();
  const [ message, setMessage ] = useState<string>("");

  // const isMobile: boolean = useMemo(() => {
  //   return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent);
  // }, [ ]);

  
  const sendMessage = () => {
    if (!socket.connected || !message) {
      return;
    }

    context.sendMessage(message);
    setMessage('');
    setTimeout(() => {
      resizeChatMessages();
    }, 50);
  }

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.charCode === 13) {
      e.preventDefault();
      sendMessage();
    }
  }

  // this is kind of required for mobile as well - android chrome devs have decided that
  // their keyboards will not emit keypress events. hurray!
  const handleChange = (message: string) => {
    setMessage(message);
  }

  const resizeChatMessages = () => {
    const chatInput = document.querySelector('#chat-message') as HTMLElement;
    const chatWrapper = document.querySelector('#chat-messages') as HTMLElement;

    if (chatInput && chatWrapper) {
      chatWrapper.style.bottom = chatInput.clientHeight + 'px';
      chatWrapper.scrollTop = chatWrapper.scrollHeight;
    }
  }

  return (
    <>
      <div id="chat-messages" className={classes.chatWrapper}>
        <List>
          {messages.map((msg: GameMessage, index: number) => {
            return (
              <ListItem key={index} alignItems="flex-start" classes={{gutters: classes.chatGutters}}>
                <ListItemText classes={{primary: classes.chatPlayer, secondary: classes.chatMessage}} primary={msg.user.name} secondary={msg.message}/>
              </ListItem>
            );
          })}
        </List>
      </div>

      <div className={classes.chatInput} id="chat-message">
        <TextField
          id="chat-input"
          label="Type message and hit enter..."
          multiline
          rowsMax={4}
          value={message}
          fullWidth
          onKeyDown={handleKeyPress}
          onChange={(e) => { handleChange(e.target.value); }}
        />
          
        <Button fullWidth color="primary" onClick={sendMessage}>Send Message</Button>
      </div>
    </>
  );
}

export default GameChat;