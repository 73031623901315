import React from "react";
import { Drawer, makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '25%',
    flexShrink: 0,
    '& .MuiDrawer-paper': {
      paddingTop: '68px'
    }
  },
  drawerPaper: {
    paddingTop: '68px',
    width: '25%',
  }
}));

interface GameDrawerProps {
  children: React.ReactNode;
  anchor: 'right' | 'left';
}

function GameDrawer({ children, anchor }: GameDrawerProps) {
  const classes = useStyles();

  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor={ anchor }
    >
      { children }
    </Drawer>
  );
}

export default GameDrawer;