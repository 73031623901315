import React, { useEffect, useState } from "react";
import { Avatar, Badge, createStyles, Drawer, makeStyles, Theme, withStyles } from "@material-ui/core";
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import { useGame } from "context/GameContext";
import clsx from "clsx";

const useStyles = makeStyles(({ palette, shadows }: Theme) => ({
  drawer: {
    height: '80vh'
  },
  chatIconWrapper: {
    position: 'fixed',
    bottom: '1em',
    right: '1em',
    zIndex: 51
  },
  chatIcon: {
    backgroundColor: palette.primary.main,
    boxShadow: shadows[5]
  },
  closeIcon: {
    position: 'absolute',
    top: '0.5em',
    right: '0.5em',
    width: 22,
    height: 22,
    zIndex: 52
  },
  hasMissedMessages: {
    backgroundColor: palette.secondary.main
  }
}));

const SmallAvatar = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 22,
      height: 22,
      fontSize: 12,
      border: `2px solid ${theme.palette.background.paper}`,
    },
  }),
)(Avatar);


interface MobileDrawerProps {
  children: React.ReactNode;
}

function MobileChatDrawer({ children }: MobileDrawerProps) {
  const classes = useStyles();
  const { messages } = useGame();
  const [ chatOpen, setChatOpen ] = useState<boolean>(false);
  const [ missedMessages, setMissedMessages ] = useState<number>(0);

  const [ lastMessageCount, setLastMessageCount ] = useState<number>(0);

  useEffect(() => {
    if (!chatOpen) {
      setMissedMessages((m) => m + (messages.length - lastMessageCount));
    }
    setLastMessageCount(messages.length);
  }, [ messages, chatOpen, lastMessageCount ])

  const openChat = () => {
    setChatOpen(true);
    setMissedMessages(0);

    setTimeout(() => {
      resizeChatMessages();
    }, 100);
  }

  const closeChat = () => {
    setChatOpen(false);
    setMissedMessages(0);
  }

  const resizeChatMessages = () => {
    const chatInput = document.querySelector('#chat-message') as HTMLElement;
    const chatWrapper = document.querySelector('#chat-messages') as HTMLElement;

    if (chatInput && chatWrapper) {
      chatWrapper.style.bottom = chatInput.clientHeight + 'px';
      chatWrapper.scrollTop = chatWrapper.scrollHeight;
    }
  }

  return (
    <>
      <Badge
        overlap="circle"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        className={classes.chatIconWrapper}
        badgeContent={<SmallAvatar className={clsx({ [classes.hasMissedMessages]: missedMessages })}>{ missedMessages }</SmallAvatar>}
        onClick={openChat}
      >

        <Avatar className={classes.chatIcon}>
          <ChatIcon />
        </Avatar>
      </Badge>

      <Drawer
        classes={{paper: classes.drawer}}
        anchor="bottom"
        open={chatOpen}
        onClose={closeChat}
      >
        <Avatar className={classes.closeIcon}>
          <CloseIcon onClick={closeChat}/>
        </Avatar>

        { children }
      </Drawer>
    </>
  );
}

export default MobileChatDrawer;