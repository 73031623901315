import React, { useMemo } from 'react';
import { useGame } from 'context/GameContext';
import { useUser } from 'context/UserContext';

export function AddGiftStatus() {
  const { game } = useGame();
  const { user } = useUser();
  
  const myPresent = useMemo(() => {
    return game?.presents?.find(e => e.providerId === user.id);
  }, [ game, user.id ])

  if (!!myPresent) {
    return (
      <>
        <h3>Great! Your gift is in the pile!</h3>
        <p>Now it's just time to wait for the game to start. Be patient, as everyone adds their gifts you'll see them show up down below. Feel free to work on your strategy in the chat with fellow participants or just plan on which virtual gift looks the best to you!</p>
        { game.presents.length < game.players.length && <p><em>Waiting on { game.players.length - game.presents.length} player(s)...</em></p>}
        { game.presents.length >= game.players.length && <p><em>Waiting for the game creator to start the game</em></p>}        
      </>
    );
  }

  return (
    <>
      <h3>Welcome!</h3>
      <p>Let's get started! You're in the game, now you need to bring a present! Since this is virtual, you need to describe your present as it is, wrapped and unwrapped. <strong>Be creative!</strong> Paint a picture and encourage people to pick your gift out of the pile. Let's get started!</p>
    </>
  );  
}

export default AddGiftStatus;