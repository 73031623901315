import * as React from "react";

function SvgGiftboxWithAHeartOnSide(props) {
  return (
    <svg viewBox="0 0 358.393 358.393" width="1em" height="1em" {...props}>
      <path d="M340.893 20.506H17.5c-9.649 0-17.5 7.851-17.5 17.5v24.656c0 9.649 7.851 17.5 17.5 17.5h2.747v244.225c0 7.444 6.056 13.5 13.5 13.5h290.898c7.444 0 13.5-6.056 13.5-13.5V80.162h2.747c9.649 0 17.5-7.851 17.5-17.5V38.006c.001-9.65-7.85-17.5-17.499-17.5zM221.254 124.757l11.261 11.262H35.247V80.162h207.876v45.249l-11.262-11.262a7.502 7.502 0 00-10.607 10.608zM35.247 151.019h197.268l-11.261 11.262a7.5 7.5 0 1010.608 10.607l11.262-11.262v161.261H35.247V151.019zm222.876 171.868v-161.26l11.261 11.261a7.48 7.48 0 005.304 2.196 7.5 7.5 0 005.304-12.803l-11.261-11.262h54.416v171.868h-65.024zm65.023-186.868H268.73l11.261-11.262a7.5 7.5 0 00-10.607-10.607l-11.261 11.261V80.162h65.022v55.857zm20.247-73.357c0 1.355-1.145 2.5-2.5 2.5H17.5c-1.355 0-2.5-1.145-2.5-2.5V38.006c0-1.355 1.145-2.5 2.5-2.5h323.393c1.355 0 2.5 1.145 2.5 2.5v24.656z" />
      <path d="M167.47 177.953c-12.405 0-21.58 8.273-26.907 13.862-5.32-5.737-14.362-13.862-27.215-13.862-19.303 0-35.251 15.461-36.31 35.199-.64 11.959 3.982 20.47 7.515 25.831 4.893 7.424 16.919 18.577 26.542 26.539 21.308 17.629 27.161 17.629 29.365 17.629 2.232 0 8.164 0 29.36-17.598 9.55-7.929 21.512-19.073 26.446-26.576 3.53-5.368 8.149-13.886 7.511-25.826-1.056-19.737-17.005-35.198-36.307-35.198zm16.263 52.782c-6.915 10.517-35.31 34.173-43.251 37.242-7.879-3.124-36.735-27.127-43.404-37.247-3.863-5.864-5.377-10.881-5.062-16.776.621-11.58 10.189-21.001 21.331-21.001 8.302 0 14.614 7.189 18.387 11.485 2.553 2.907 4.758 5.419 8.675 5.419 3.935 0 6.22-2.493 9.112-5.648 3.858-4.21 10.316-11.256 17.948-11.256 11.141 0 20.709 9.421 21.329 21 .315 5.886-1.199 10.905-5.065 16.782z" />
    </svg>
  );
}

export default SvgGiftboxWithAHeartOnSide;
