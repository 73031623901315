import { TextField } from '@material-ui/core';
import React from 'react';

import { AddGiftProps } from './AddGift';

export function DescribeWrapping({ present, setPresentProp }: AddGiftProps) {
  return (
    <>
      <h3>2) How did you wrap it (optional)?</h3>
      <p>The pile needs wrapped gifts! You probably didn't wrap your gift, but if you did, how would you have wrapped it? <strong>This will be shown when your gift has not been picked yet.</strong> For example:</p>
      
      <ul>
        <li><em>A small gift bag with Santa dancing with Reindeer, with a gift popping out of salmon colored tissue paper</em></li>
        <li><em>A hastily wrapped box, covered in yellow and black warning tape. It's also glowing...</em></li>
        <li><em>A box, immaculately wrapped with clean red wrapping paper and a red bow.</em></li>
      </ul>

      <TextField
        variant="outlined"
        margin="normal"
        multiline
        fullWidth
        id="wrapped"
        label="How did you wrap it?"
        name="wrapped"
        inputProps={{maxLength: 100}}
        value={present.wrappedDescription}
        onChange={(e) => setPresentProp('wrappedDescription', e.target.value)}
      />
    </>
  );
}

export default DescribeWrapping;