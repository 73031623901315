import React, { useEffect, useState } from "react";
import { useUser } from 'context/UserContext';
import { Avatar, Typography, TextField, Button, makeStyles, Container, ButtonGroup, Chip, Paper } from "@material-ui/core";
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import ErrorIcon from '@material-ui/icons/Error';

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  paper: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorChip: {
    marginBottom: '1em',
    width: '100%',
    justifyContent: 'left'
  }
}));

function Identify() {
  const { user, setUser } = useUser();

  const [ type, setType ] = useState<string>('signup');
  const [ name, setName ] = useState<string>(user.name as string);
  const [ email, setEmail ] = useState<string>(user.email as string);
  const [ deliveryInstructions, setDeliveryInstructions ] = useState<string>(user.deliveryInstructions as string);
  const [ password, setPassword ] = useState<string>('');
  const [ errors, setErrors ] = useState<string[]>([]);
  const classes = useStyles();

  const identify = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const response = await fetch(`${process.env[`REACT_APP_SOCKET_SERVER`]}/${type}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },  
      body: JSON.stringify({
        name,
        email,
        password,
        deliveryInstructions
      })
    });

    const json = await response.json();

    if (json.errors) {
      setErrors(json.errors);
      return;
    }

    const { user, session } = json;
    const data = { ...user, sessionId: session.id };
    setUser(data);
  }

  useEffect(() => {
    setErrors([]);
  }, [ name, email, password])

  return (
    <Container component="main" maxWidth="md">
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <CompareArrowsIcon />
          </Avatar>
          
          <Typography component="h1" variant="h3">
            Welcome!
          </Typography>
          
          <Typography component="p" variant="body1">
            Ready for some good, old-fashioned, backstabbing fun? Get started by entering your name. If you have a game to join you will do
            that shortly, otherwise you can create a new game.
          </Typography>
          
          <Container maxWidth="sm">
            <form className={classes.form} onSubmit={identify} noValidate>
              <ButtonGroup fullWidth color="primary" aria-label="outlined primary button group">
                <Button variant={type === 'signup' ? 'contained' : 'outlined'} onClick={() => setType('signup')}>Signup</Button>
                <Button variant={type === 'login' ? 'contained' : 'outlined'} onClick={() => setType('login')}>Login</Button>
              </ButtonGroup>

              { type === 'signup' && <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="name"
                label="Name"
                name="name"
                autoComplete="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                autoFocus
              /> }
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="email"
                label="Email Address"
                id="email"
                autoComplete="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                required
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />

              {type === 'signup' && <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                multiline
                rows={3}
                required
                name="deliveryInstructions"
                label="How should your gift giver send you your gift? Put an address or a phone number for contact"
                type="text"
                id="deliveryInstructions"
                autoComplete="deliveryInstructions"
                value={deliveryInstructions}
                onChange={(e) => setDeliveryInstructions(e.target.value)}
              /> }

              { errors &&       
                errors.map((error, index) => {
                  return <Chip
                    key={index}
                    icon={<ErrorIcon />}
                    label={error}
                    color="secondary"
                    className={classes.errorChip}
                  />
                })
              }

              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                className={classes.submit}
              >
                Continue
              </Button>
            </form>
          </Container>
        </Paper>
      </div>
    </Container>
  );
}

export default Identify;