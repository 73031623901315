// @todo unused at the moment, this is pretty not working
import React from "react";

import { Grid, makeStyles, Paper } from "@material-ui/core";

import LockIcon from '@material-ui/icons/Lock';
import { useGame } from "context/GameContext";
import NameTag from "./NameTag";
import { User } from "types";
import { SelectablePresent } from "./SelectablePresent";
import { useUser } from 'context/UserContext';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '0.5em',
    minHeight: '140px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative'
  },
  active: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.info.light
    }
  },
  unwrappedIcon: {
    width: '33%',
    height: '50px'
  },
  lockedGift: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    width: '100%',
    backgroundColor: 'rgb(50, 50, 50, 0.2)',
    opacity: '40%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  lockedGiftIcon: {
    width: '3em', 
    height: '3em', 
    color: 'rgb(0,0,0, .4)',
  },
  giftHolder: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    width: '100%'
  },
  wrappedDescription: {
    textAlign: 'center'
  },
  unwrappedImageWrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  },
  overlayName: {
    position: 'absolute',
    bottom: '0.5em',
    left: '0.5em',
    padding: '.25em',
    borderRadius: '0.25em',
    background: 'rgb(255,255,255,0.9)'
  },
  header: {
    background: 'white',
    padding: '5px',
    borderRadius: '5px'
  }
}));

function GamePresents() {
  const classes = useStyles();
  const { game, activeRound } = useGame();
  const { user } = useUser();

  const roundPicks = activeRound ? activeRound?.actions.map(e => e.present.id) : [];
  const actions = activeRound ? activeRound.actions : [];
  const lastAction = actions.length ? actions[actions.length - 1] : null;

  const giftHasStealsLeft = (presentId: string | undefined) => {
    return !game.stealsPerGiftPerRound || roundPicks.filter(pick => pick === presentId).length < game.stealsPerGiftPerRound;
  };

  const wasStolenFromMe = (presentId: string | undefined) => {
    return lastAction?.present.id === presentId && lastAction?.victim?.id === user.id;
  };


  const presents = {
    wrapped: game.presents.filter(present => !present.holderId),

    // these gifts have not been picked yet this round
    unwrapped: game.presents.filter(present => present.holderId && !wasStolenFromMe(present.id) && giftHasStealsLeft(present.id)),

    // these are all gifts that are either locked for the round or locked for the current user
    unavailable: game.presents.filter(present => {
      // needs to have been stolen at least once to be unavailable
      if (!present.holderId) {
        return false;
      }

      // unavailable if it was just stolen from you
      if (wasStolenFromMe(present.id)) {
        return true;
      }

      // unavailable if this gift has been stolen too many times
      return !giftHasStealsLeft(present.id);
    })
  };

  return (
    <>
      { (!!presents.unwrapped.length || !!presents.unavailable.length) && 
        <>
          <h3 className={classes.header}>Opened Gifts</h3>
          <Grid container spacing={1}>
            { presents.unwrapped?.map((present) => <SelectablePresent key={present.id} present={present} /> )}

            { presents.unavailable?.map((present) => {
              return (
                <Grid key={present.id} item xs={6} sm={4} md={3}>
                  <Paper className={classes.paper}>
                    <div className={classes.lockedGift}>
                      <LockIcon className={classes.lockedGiftIcon}/>
                    </div>
                    <p>{ present.unwrappedDescription }</p>
                  { present.holderId && <NameTag firstName={true} name={(game.players.find(e => e.id === present.holderId) as User).name}/> }
                  </Paper>
                </Grid>
              )
            })}

          </Grid> 
        </>
      }


      { !!presents.wrapped.length && 
        <>
          <h3 className={classes.header}>Gifts Left in the Pile</h3>

          <Grid container spacing={1}>
            { presents.wrapped?.map((present) => <SelectablePresent key={present.id} present={present} /> )}
          </Grid>
        </>
      }
    </>
  );
}

export default GamePresents;