// @todo unused at the moment, this is pretty not working
import React, { useMemo } from "react";
import { Avatar, Chip, Grid, makeStyles, Paper } from "@material-ui/core";
import { useGame } from "context/GameContext";
import { useUser } from "context/UserContext";
import { Present } from "types/Present";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1em',
  },
  historyWrapper: {
    maxHeight: '90vh',
    overflowY: 'auto',
    [theme.breakpoints.down('xs')]: {
      maxHeight: '160px'
    }
  },
  chip: {
    width: '100%',
    marginBottom: '.2em',
    justifyContent: 'left',
    height: 'auto',
    minHeight: '32px',
    borderRadius: '6px',
    '&.active': {
      backgroundColor: theme.palette.success.light
    }
  },
  chipLabel: {
    whiteSpace: 'pre-wrap',
    paddingTop: '4px',
    paddingBottom: '4px'
  },
  activeAvatar: {
    backgroundColor: theme.palette.success.dark,
    color: 'white !important'
  },
  imageWrapper: {
    width: '100%',
    paddingTop: '56.5%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center'
  }
}));

function GameSummary() {
  const classes = useStyles();
  const { game, activeRound } = useGame();
  const { user } = useUser();

  const exchange = useMemo(() => {
    if (!game.completedAt) {
      return {};
    }

    const received = game.presents.find(e => e.holderId === user.id) as Present;
    const given = game.presents.find(e => e.providerId === user.id) as Present;

    return {
      received: { ...received, provider: game.players.find(e => e.id === received?.providerId)},
      given: { ...given, holder: game.players.find(e => e.id === given?.holderId)}
    }
  }, [ game.presents, game.completedAt, game.players, user.id, activeRound ]) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Paper className={classes.root}>
      { !!game.completedAt && !!exchange.received && !!exchange.given && 
        <div>
          <Chip
            color="secondary"
            classes={{root: classes.chip, avatar: classes.activeAvatar, label: classes.chipLabel}}
            avatar={<Avatar>!!</Avatar>}
            label={`The game is over! Great job everyone, now figure out how to get your gifts to each other!`}
          /> 

          <Grid container spacing={1}>
            { exchange.received.providerId !== exchange.given.providerId &&
              <>
                <Grid item xs={12} sm={6}>
                  <h3>You received a gift from { exchange.received.provider?.name }</h3>
                  { !!exchange.received.imageName && 
                    <div className={classes.imageWrapper} style={{backgroundImage: `url(${process.env.REACT_APP_API_SERVER}/static/${exchange.received.imageName})`}} />
                  }
                  <p>{exchange.received.unwrappedDescription}</p>
                </Grid>

                <Grid item xs={12} sm={6}>
                  <h3>You gave a gift to { exchange.given?.holder?.name }</h3>
                  { !!exchange.given.imageName && 
                    <div className={classes.imageWrapper} style={{backgroundImage: `url(${process.env.REACT_APP_API_SERVER}/static/${exchange.given.imageName})`}} />
                  }
                  <p>{exchange.given.unwrappedDescription}</p>
                </Grid>

                <Grid item xs={12}>
                  <h4>How to finish this game</h4>
                  <p>You have to send the gift you brought! Here are the instructions { exchange.given?.holder?.name } gave to get their gift to them:</p>
                  <p>{ exchange.given?.holder?.deliveryInstructions }</p>
                </Grid>
              </>
            } 

            { exchange.received.providerId === exchange.given.providerId &&
              <>
                <Grid item xs={12} sm={6}>
                  <h3>You sneaky sneak! You got your own gift!</h3>
                  { !!exchange.received.imageName && 
                    <div className={classes.imageWrapper} style={{backgroundImage: `url(${process.env.REACT_APP_API_SERVER}/static/${exchange.received.imageName})`}} />
                  }
                  <p>{exchange.received.unwrappedDescription}</p>
                </Grid>
              </>
            } 

          </Grid>
        </div>
      }
    </Paper>
  );
}

export default GameSummary;