import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { ChatEvent, GameEvent, UserEvent } from 'socket/events';
import { GameMessage, User } from 'types';
import { Game, GameUpdate } from 'types/Game';
import { Present } from 'types/Present';
import { Round } from 'types/Round';
import { RoundAction } from 'types/RoundAction';
import { RetryEvent } from 'types/Socket';
import { useGameConnection } from './GameConnectionContext';
import { useUser } from './UserContext';

type SetGame = (game: Game) => void;
type SetError = (error: string) => void;
type JoinRoom = (room: string) => void;

type GameContext = {
  game: Game;
  error: string;
  messages: GameMessage[];
  connected: boolean;
  room: string;
  setGame: SetGame;
  setError: SetError;
  joinRoom: JoinRoom;
  activeRounds: Round[];
  activeRound: Round | undefined;
  activePlayer: User | undefined;
}

const GameStateContext = createContext<GameContext>({} as GameContext);

export function GameProvider({ children }: { children: React.ReactNode}) {
  const [ game, setGame ] = useState<Game>({} as Game);
  const [ messages, setMessages ] = useState<GameMessage[]>([]);
  const [connected, setConnected] = useState<boolean>(false);
  const [room, joinRoom] = useState<string>('');
  const [error, setError] = useState<string>('');
  const context = useGameConnection();
  const { user, setUser, updateUserGame } = useUser();

  let newMessageAudio = new Audio("/sounds/message.mp3")

  const playNewMessage = () => {
    if (!user.disableAudio) {
      newMessageAudio.pause();
      newMessageAudio.currentTime = 0;
      newMessageAudio.play();
    }
  }

  useEffect(() => {    
    if (context.connected() || !room) {
      return;
    }

    console.debug('[GameContext](useEffect)')

    setConnected(true);
    context.connect(user, room);
        
    const socket = context.getSocket();

    socket.on(UserEvent.LOSTIDENTITY, (retry: RetryEvent) => {
      console.debug('[incoming](lostIdentity)', game);

      if (!user) {
        console.debug('... no user identity');
        return;
      }

      context.identify(user);
      setTimeout(() => {
        context.joinRoom(room);

        setTimeout(() => {
          context.getSocket().emit(retry.event, retry.data);
        }, 300);
      }, 300);
    });

    socket.on(UserEvent.UNAUTHORIZED, () => {
      console.debug('[incoming](unauthorized)');
      setUser({} as User);
      window.location.href = '/';
    });

    socket.on(GameEvent.GAMEINFO, (game: Game) => {
      console.debug('[incoming](gameInfo)', game);
      setGame(game);
      updateUserGame(game);
      context.getMessages();
    });

    socket.on(GameEvent.GAMEUPDATE, (data: GameUpdate) => {
      console.debug('[incoming](gameUpdated)', data);

      if (data.slug && data.slug !== room) {
        window.location.href = `/game/${data.slug}`
        return;
      }

      setGame((game) => { 
        setTimeout(() => {
          updateUserGame({ ...game, ...data});
        }, 300);

        return { ...game, ...data}
      });

    });

    socket.on(GameEvent.PRESENTADDED, (data: Present) => {
      console.debug('[incoming](presentAdded)', data);
      setGame((game) => {
        // get a list of presents without the current present added if it exists
        const presents = game.presents;
        const existingIndex = presents.findIndex(e => e.id === data.id);

        if (existingIndex >= 0) {
          presents[existingIndex] = data
        } else {
          presents.push(data);
        }

        // set the state
        return { ...game, presents }
      })
    });

    socket.on(GameEvent.PLAYERJOINED, (player: User) => {
      console.debug('[incoming](playerJoined)', player);
      setGame((game) => {
        const players = game.players;
        const existing = players?.find(e => e.id === player.id);
        if (!existing) {
          players?.push(player);
        } else {
          existing.connected = true;
        }

        return { ...game, players}
      })
    });

    socket.on(GameEvent.PICKPRESENT, (action: RoundAction) => {
      console.debug('[gamepresents.incoming](pickpresent)', action);

      setGame((game: Game) => {
        // set present holder
        const presents = game.presents;
        const presentIndex = presents.findIndex(e => e.id === action.present.id);
        presents[presentIndex].holderId = action.player.id;
        presents[presentIndex].unwrappedDescription = action.present.unwrappedDescription;
        presents[presentIndex].imageName = action.present.imageName;
        
        // update round actions
        const rounds = game.rounds;
        const roundIndex = rounds.findIndex(e => e.id === action.roundId);
        rounds[roundIndex].actions.push(action);

        // last turn of a round, bump this stuff
        if (!action.victim?.id) {
          rounds[roundIndex].completedAt = new Date();

          const nextRoundIndex = rounds.findIndex(e => e.sort === rounds[roundIndex].sort + 1);
          if (nextRoundIndex >= 0) {
            rounds[nextRoundIndex].startedAt = new Date();
          }
        }

        // set the state
        return { ...game, presents, rounds}
      })
    });

    socket.on(ChatEvent.MESSAGE, (m: GameMessage) => {
      console.debug('[gamechat.incoming](message)', m);
      
      setMessages((messages) => [...messages, m]);

      const chatWrapper = document.querySelector('#chat-messages');
      if (chatWrapper) {
        chatWrapper.scrollTop = chatWrapper?.scrollHeight;
      }

      // if the message didn't come from this user, don't play a sound
      if (m.user.id !== user.id) {
        playNewMessage();
      }
      
    });

    socket.on(ChatEvent.MESSAGES, (m: GameMessage[]) => {
      console.debug('[gamechat.incoming](messages)', m);
      setMessages((messages) => {
        const newMessages = m.filter(message => !messages.map(e => e.id).includes(message.id));
        return [ ...messages, ...newMessages ]
      });

      const chatWrapper = document.querySelector('#chat-messages');
      if (chatWrapper) {
        chatWrapper.scrollTop = chatWrapper?.scrollHeight;
      }
    });

    socket.on(GameEvent.ERROR, (error: { message: string }) => {
      console.debug('[incoming](error)', error);
      setError(error.message);
    });


    socket.on(GameEvent.PLAYERLEFT, (userId: string) => {
      console.debug('[incoming](playerLeft)', userId);
      setGame((game) => { 
        return { ...game, players: game.players?.map((e) => {
          if (e.id === userId) {
            e.connected = false;
          }
          return e;
        })};
      });
    });

    socket.on('disconnect', () => {
      console.debug('[incoming](disconnect)');
      setConnected(false);
    });

    return function cleanup() {
      console.debug('useeffect... disconnecting')
      socket.disconnect()
    };
  }, [room]); // eslint-disable-line react-hooks/exhaustive-deps

  const activeRounds = useMemo((): Round[] => {
    return game.rounds?.filter(e => !!e.startedAt);
  }, [game]);

  const activeRound = useMemo((): Round | undefined => {
    return game.rounds?.filter(e => e.startedAt !== null && e.completedAt === null).pop();
  }, [game]);
  
  const activePlayer = useMemo((): User | undefined => {
    const round = activeRound;

    if (!round) {
      return undefined;
    }
  
    if (round.actions.length) {
      const playerId = round.actions
        .map(e => e.victim?.id)
        .filter(e => e)
        .pop() as string;

      return game.players.find(e => e.id === playerId);
    }
    
    return game.players.find(e => e.id === round.playerId);
  }, [ activeRound, activeRound?.actions?.length, game.players ]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GameStateContext.Provider value={{game, error, connected, messages, room, setGame, setError, joinRoom, activeRounds, activeRound, activePlayer}}>
      { children }
    </GameStateContext.Provider>
  )
}

export function useGame(): GameContext {
  const gameStateContext = useContext(GameStateContext);

  if (gameStateContext === undefined) {
    throw new Error('useGame must be used inside a GameProvider');
  }

  return gameStateContext;
}