// @todo unused at the moment, this is pretty not working
import React, { useCallback, useMemo, useState } from "react";
import { useGameConnection } from 'context/GameConnectionContext';

import { useUser } from "context/UserContext";
import { Button, makeStyles, Paper } from "@material-ui/core";
import { useGame } from "context/GameContext";

import svgs from 'icons/index';
import { PresentUpdate } from "types";

import AddGiftStatus from "./AddGiftStatus";
import DescribeGift from './DescribeGift';
import DescribeWrapping from './DescribeWrapping';
import PickIcon from './PickIcon';
import AddAnImage from './AddAnImage';


export interface AddGiftProps {
  present: PresentUpdate,
  setPresentProp: Function
}

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: '1em',
    marginBottom: '2em'
  },
  buttonWrapper: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row-reverse',
    '& > *': {
      marginLeft: '.5em'
    }
  }
}));

enum Steps {
  STATUS = 'status',
  DESCRIBEGIFT = 'describegift',
  DESCRIBEWRAPPING = 'describewrapping',
  ADDIMAGE = 'addimage',
  PICKICON = 'pickicon'
}

function AddGift() {
  const classes = useStyles();
  const context = useGameConnection();

  const { user } = useUser();
  const { game } = useGame();

  const [ file, setFile ] = useState<File | undefined>(undefined);
  const [ present, setPresent ] = useState<PresentUpdate>({ wrappedIcon: Object.keys(svgs).shift() } as PresentUpdate);
  const [ step, setStep ] = useState<Steps>(Steps.STATUS);

  const myPresent = useMemo(() => {
    return game?.presents?.find(e => e.providerId === user.id);
  }, [ game, user.id ])

  if (myPresent?.id && !present.id) {
    setPresent(myPresent);
  }


  
  const addPresent = async () => {
    const data = { ...present };

    if (file) {
      const savedImage = await saveImage(file);
      data.imageName = savedImage;
    }

    setPresent(data);
    context.addPresent(data);
    setStep(Steps.STATUS);
  }

  const saveImage = async (file: File): Promise<string | undefined> => {
    const formData = new FormData();
    formData.append('userId', user.id);
    formData.append('gameId', game.id as string);
    formData.append('image', file);

    const response = await fetch(`${process.env[`REACT_APP_SOCKET_SERVER`]}/upload`, {
      method: 'POST',
      body: formData
    });
    
    setFile(undefined);

    const json = await response.json();
    
    if (json.error) {
      alert(json.error);
    }

    return json.image;
  }

  const setPresentProp = (prop: string, value: any) => {
    setPresent((present) => {
      return { ...present, [prop]: value };
    });
  }

  const canContinue = () => {
    if (step === Steps.DESCRIBEGIFT) {
      return present.unwrappedDescription?.length > 2;
    }

    return true;
  }

  const nextStep = useCallback(() => {
    const steps = Object.values(Steps);
    const stepIndex = steps.findIndex(e => e === step);

    if (stepIndex === steps.length - 1) {
      addPresent();
      return;
    }

    setStep(steps[stepIndex + 1] as Steps);
  }, [ step, present.wrappedIcon ]) // eslint-disable-line react-hooks/exhaustive-deps

  const previousStep = useCallback(() => {
    const steps = Object.values(Steps);
    const stepIndex = steps.findIndex(e => e === step);

    if (stepIndex === 0) {
      return;
    }

    setStep(steps[stepIndex - 1] as Steps);
  }, [ step ])

  const isLastStep = useMemo(() => {
    const steps = Object.values(Steps);
    return steps.findIndex(e => e === step) === steps.length-1;
  }, [ step ])

  const isFirstStep = useMemo(() => {
    const steps = Object.values(Steps);
    return steps.findIndex(e => e === step) === 0;
  }, [ step ])




  let Component: React.ReactElement = <></>;

  if ( step === Steps.STATUS) {
    Component = <AddGiftStatus />
  }

  if (step === Steps.DESCRIBEGIFT) {
    Component = <DescribeGift present={present} setPresentProp={setPresentProp} />
  }

  if (step === Steps.DESCRIBEWRAPPING) {
    Component = <DescribeWrapping present={present} setPresentProp={setPresentProp} />
  }

  if (step === Steps.ADDIMAGE) {
    Component = <AddAnImage present={present} file={file} setFile={setFile} />
  }

  if (step === Steps.PICKICON) {
    Component = <PickIcon present={present} setPresentProp={setPresentProp} />
  }

  return (
    <Paper elevation={3} className={classes.paper}>
      { Component }

      <div className={classes.buttonWrapper}>
        <Button
          type="button"
          variant="contained"
          color="primary"
          disabled={!canContinue()}
          onClick={nextStep}
        >
          { step === Steps.STATUS && (!!myPresent ? 'Edit My Gift' :  'Add My Gift')}
          { step !== Steps.STATUS && (isLastStep ? 'Add Gift to Pile' :  'Continue') }
        </Button>

        {!isFirstStep &&
          <Button
            type="button"
            variant="outlined"
            color="primary"
            onClick={previousStep}
          >
            Go Back
          </Button>
        }
      </div>
    </Paper>  
  );
}

export default AddGift;