import * as React from "react";

function SvgGiftboxWithBigRibbon2(props) {
  return (
    <svg viewBox="0 0 340.726 340.726" width="1em" height="1em" {...props}>
      <path d="M296.813 77.996h-.965c.362-1.4.48-2.712.48-3.814 0-1.143.055-2.809.118-4.738.486-14.79 1.497-45.566-13.148-60.7C277.684 2.941 270.521 0 262.008 0c-22.32 0-50.588 20.475-61.706 29.278-4.302 3.406-20.978 16.979-29.94 29.503-8.962-12.524-25.637-26.097-29.94-29.503C129.305 20.475 101.037 0 78.717 0c-8.513 0-15.675 2.941-21.29 8.743-14.645 15.134-13.633 45.91-13.147 60.7.063 1.93.118 3.596.118 4.739 0 1.102.117 2.414.48 3.814h-.965c-20.678 0-37.5 16.822-37.5 37.5v187.73c0 20.678 16.822 37.5 37.5 37.5h252.9c20.678 0 37.5-16.822 37.5-37.5V115.495c0-20.677-16.822-37.499-37.5-37.499zm-32.316 15l54.816 54.816v74.247L190.25 92.996h74.247zm-53.296-53.203C231.19 24.269 250.184 15 262.008 15c4.415 0 7.754 1.326 10.51 4.174 10.225 10.565 9.321 38.043 8.936 49.776-.064 1.969-.121 3.681-.129 5.122-1.56 1.356-80.582 2.627-89.07 1.667-9.599-1.086-12.107-2.967-12.607-3.452 1.376-4.07 12.553-17.738 31.553-32.494zM68.207 19.174C70.962 16.326 74.302 15 78.717 15c11.825 0 30.818 9.269 50.808 24.793 19 14.756 30.177 28.424 31.554 32.494-.5.485-3.008 2.366-12.607 3.452-8.488.96-87.519-.313-89.073-1.67-.003-1.373-.061-3.113-.126-5.119-.387-11.733-1.29-39.211 8.934-49.776zM21.413 191.501l134.233 134.224H81.399l-59.986-59.977v-74.247zm0 111.725V286.96l38.772 38.766H43.913c-12.407 0-22.5-10.094-22.5-22.5zm297.9 0c0 12.407-10.094 22.5-22.5 22.5H176.858L21.413 170.289v-54.793c0-12.407 10.093-22.5 22.5-22.5h125.124l150.276 150.276v59.954zm-33.604-210.23h11.104c12.406 0 22.5 10.093 22.5 22.5V126.6l-33.604-33.604z" />
    </svg>
  );
}

export default SvgGiftboxWithBigRibbon2;
