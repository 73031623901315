import * as React from "react";

function SvgGifboxWrappedWithRibbon(props) {
  return (
    <svg viewBox="0 0 350.171 350.171" width="1em" height="1em" {...props}>
      <path d="M316.535 75.778h-16.044c.255-1.138.393-2.334.393-3.597 0-1.151.056-2.808.12-4.726.513-15.32 1.467-43.837-12.849-58.641C282.498 2.965 275.234 0 266.564 0c-22.32 0-50.588 20.475-61.706 29.277-4.302 3.406-20.977 16.979-29.94 29.503-8.962-12.524-25.638-26.096-29.94-29.503C133.861 20.475 105.592 0 83.272 0c-8.67 0-15.934 2.965-21.59 8.814-14.316 14.804-13.361 43.321-12.849 58.641.064 1.918.12 3.575.12 4.726 0 1.262.137 2.458.393 3.597h-15.71c-9.649 0-17.5 7.851-17.5 17.5v237.725c0 5.756 2.806 10.856 7.108 14.047 1.487 2.284 4.68 5.121 11.392 5.121h280.898c6.712 0 9.906-2.836 11.392-5.121 4.302-3.191 7.108-8.291 7.108-14.047V93.278c.001-9.65-7.85-17.5-17.499-17.5zM31.136 100.506L153.522 204.64H31.136V100.506zm275.01-8.728L178.604 200.299c-.861.732-2.144 1.153-3.518 1.153s-2.657-.42-3.518-1.152L44.025 91.778h262.121zm12.889 8.728V204.64H196.649l122.386-104.134zM31.136 219.64h125.521L31.136 326.442V219.64zm14.849 113.863l125.583-106.854c.861-.732 2.144-1.153 3.518-1.153s2.657.42 3.518 1.152l125.583 106.854H45.985zM193.513 219.64h125.521v106.802L193.513 219.64zm22.243-179.847C235.746 24.269 254.739 15 266.564 15c4.574 0 8.008 1.348 10.807 4.242 9.895 10.231 9.016 36.496 8.64 47.712-.069 2.053-.128 3.825-.128 5.228v.042c-.326.356-2.587 2.375-12.672 3.515-.122.014-.25.026-.373.039h-75.657c-.122-.013-.248-.025-.369-.039-9.601-1.086-12.11-2.968-12.609-3.453 1.377-4.07 12.553-17.737 31.553-32.493zM76.625 75.739c-10.084-1.141-12.346-3.159-12.672-3.515v-.042c0-1.402-.059-3.175-.128-5.228-.375-11.216-1.255-37.48 8.64-47.712C75.264 16.348 78.698 15 83.272 15c11.825 0 30.818 9.269 50.808 24.793 19 14.756 30.177 28.423 31.554 32.494-.5.485-3.009 2.367-12.61 3.453-.12.014-.247.025-.369.039H76.999c-.123-.015-.252-.026-.374-.04z" />
    </svg>
  );
}

export default SvgGifboxWrappedWithRibbon;
