import * as React from "react";

function SvgValentinesGiftbox(props) {
  return (
    <svg viewBox="0 0 401.002 401.002" width="1em" height="1em" {...props}>
      <path d="M383.502 39.95H17.5C7.851 39.95 0 47.801 0 57.45v43.376c0 9.649 7.851 17.5 17.5 17.5h14.052v228.225c0 7.995 6.505 14.5 14.5 14.5H354.95c7.995 0 14.5-6.505 14.5-14.5V118.327h14.052c9.649 0 17.5-7.851 17.5-17.5V57.45c0-9.649-7.851-17.5-17.5-17.5zM199.591 282.24c-3.191-.961-13.657-7.235-30.247-21.095-14.536-12.145-27.256-24.787-31.652-31.459-5.657-8.585-7.872-15.977-7.406-24.714.931-17.354 15.317-31.472 32.071-31.472 12.662 0 21.851 10.465 27.34 16.717 3.307 3.766 5.695 6.486 9.806 6.486 4.171 0 6.668-2.724 10.447-6.848 5.607-6.117 14.992-16.356 26.698-16.356 16.753 0 31.139 14.118 32.068 31.471.466 8.722-1.75 16.115-7.409 24.721-4.451 6.769-17.146 19.45-31.589 31.556-16.501 13.833-26.938 20.059-30.127 20.993zm-84.284-78.068c-.16 3.003-.06 5.837.231 8.513H46.552v-94.358h146.449v53.637c-7.142-6.607-17.376-13.463-30.645-13.463-25.009-.001-45.676 20.06-47.049 45.671zM17.5 103.327c-1.355 0-2.5-1.145-2.5-2.5V57.45c0-1.355 1.145-2.5 2.5-2.5h175.501v48.376H17.5zm29.052 242.725V227.685h72.988c1.76 4.068 3.801 7.487 5.626 10.255 6.545 9.934 22.826 25.014 35.88 35.814 16.944 14.019 26.483 19.694 31.955 21.992v50.306H46.552zm307.898 0H208.001v-51.191c7.268-3.433 17.329-10.47 30.088-21.062 12.952-10.754 29.146-25.823 35.751-35.865 1.926-2.929 3.943-6.32 5.655-10.25h74.956v118.368zm0-133.367h-70.972c.282-2.669.377-5.5.216-8.515-1.372-25.609-22.037-45.669-47.047-45.669-11.997 0-21.562 5.929-28.646 12.103v-52.277H354.45v94.358zm31.552-111.858c0 1.355-1.145 2.5-2.5 2.5H208.001V54.95h175.501c1.355 0 2.5 1.145 2.5 2.5v43.377z" />
    </svg>
  );
}

export default SvgValentinesGiftbox;
