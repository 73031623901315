import { Game } from "./Game";

export type User = {
  id?: string;
  sessionId?: string;
  email: string;
  name: string;
  deliveryInstructions: string;
  connected?: boolean;
  disableAudio: boolean;
}

export class SessionUser implements User {
  id: string = '';
  sessionId: string = ''
  name: string = '';
  games: Game[] = [];
  myGames: Game[] = [];
  email: string = '';
  deliveryInstructions: string = '';
  
  connected: boolean = true;
  disableAudio: boolean = false;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }

  isDefined() {
    return !!this.name;
  }

  setName(name: string) {
    this.name = name;
    this.persist();
  }

  setEmail(email: string) {
    this.email = email;
    this.persist();
  }

  setSessionId(sessionId: string) {
    this.sessionId = sessionId;
    this.persist();
  }



  private persist() {
  
  }
}
