// @todo unused at the moment, this is pretty not working
import React from "react";

import { useUser } from "context/UserContext";
import { useGame } from "context/GameContext";
import { Avatar, Drawer, List, ListItem, ListItemAvatar, ListItemText, makeStyles } from "@material-ui/core";
import UserService from 'services/UserService';

const useStyles = makeStyles((theme) => ({
  drawer: {
    width: '25%',
    flexShrink: 0
  },
  drawerPaper: {
    width: '25%',
  },
  avatarWrapper: {
    minWidth: '42px'
  },
  avatar: {
    width: '36px',
    height: '36px',
    fontSize: '1em'
  },
  listGutters: {
    padding: '1px 12px'
  },
  playerListName: {
    marginTop: '10px'
  },
  playerName: {
    textTransform: 'capitalize',
    fontWeight: 'bold',
    fontSize: '0.9em'
  },
  disconnectedMask: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: 'white',
    opacity: '0.8'
  }
}));

function PlayerStatus() {
  const { user } = useUser();
  const { game } = useGame();
  const classes = useStyles();
  
  return (
    <Drawer
      className={classes.drawer}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="left"
    >
      { !game.startedAt &&
        <List>
          { game.players && game.players.map((player) => {
            const tag = UserService.getTag(player.name);
            const gift = game.presents?.find(e => e.providerId === player.id);

            let status = gift ? 'Gift added, ready to go!' : 'Joined, adding gift...';

            if (player.connected === false) {
              status = 'Player disconnected...';
            }

            return (
              <ListItem key={player.id} alignItems="flex-start" classes={{gutters: classes.listGutters}}>
                <ListItemAvatar classes={{root: classes.avatarWrapper}}>
                  <Avatar className={classes.avatar} style={{backgroundColor: UserService.getColor(tag).hex}} alt={player.name}>{tag}</Avatar>
                </ListItemAvatar>
                <ListItemText classes={{root: classes.playerListName, primary: classes.playerName}} primary={player.name + (player.id === user.id ? ' (you)' : '')} secondary={status}/>
                { player.connected === false && <div className={classes.disconnectedMask}></div> }
              </ListItem>
            );
          })}
        </List>
      }

    </Drawer>
  );
}

export default PlayerStatus;