import React, { useEffect, useMemo, useState } from "react";

import { RouteComponentProps } from "react-router-dom";
import { useUser } from "context/UserContext";
import { AppBar, Button, Hidden, makeStyles, Theme, Toolbar, Typography } from "@material-ui/core";
import GameChat from "components/GameChat";
import PlayerStatus from "components/PlayerStatus";
import AddGift from 'components/AddGift/AddGift';
import GamePresents from "components/GamePresents";
import GameAdmin from "components/GameAdmin";
import { useGame } from "context/GameContext";
import ReconnectDialog from "components/ReconnectDialog";
import GameStatus from "components/GameStatus";
import GameDrawer from "components/GameDrawer";
import MobileChatDrawer from "components/MobileChatDrawer";
import GameSummary from "components/GameSummary";
import Identify from "components/Identify";
import HomeIcon from '@material-ui/icons/Home';
import GameInfo from "components/GameInfo";
import GameError from "components/GameError";

import MusicNoteIcon from '@material-ui/icons/MusicNote';
import MusicOffIcon from '@material-ui/icons/MusicOff';

const useStyles = makeStyles(({ breakpoints}: Theme) => ({
  root: {

  },
  appBar: {
    zIndex: 500000,
    width: `75%`,
    left: '0%',
    [breakpoints.down('xs')]: {
      width: '100%',
      left: 0
    }
  },
  appbarTitle: {
    flexGrow: 1,
  },
  content: {
    width: '50%',
    marginLeft: '25%',
    marginTop: '64px',
    padding: '10px',
    [breakpoints.down('xs')]: {
      width: '100%',
      marginLeft: 0
    }
  },
  headerButton: {
    minWidth: '48px'
  }
}));

interface GameSceneParams {
  room: string;
}

function GameScene({ match }: RouteComponentProps<GameSceneParams>) {
  const { user, toggleAudio } = useUser();
  const { game, error, room, activePlayer, activeRound, joinRoom } = useGame();
  const [ lastActivePlayerId, setLastActivePlayerId ] = useState<string | undefined>(undefined);
  const classes = useStyles();

  useEffect(() => {
    if (!room && user.id) {
      joinRoom(match.params.room);
    }
  }, [ room, user, joinRoom, match.params.room ]);

  let youreUpAudio = new Audio("/sounds/youreup.mp3")

  useEffect(() => {
    if (activePlayer && lastActivePlayerId !== activePlayer.id) {
      if (activePlayer.id === user.id && !user.disableAudio) {
          youreUpAudio.pause();
          youreUpAudio.currentTime = 0;
          youreUpAudio.play()
      }

      setLastActivePlayerId(activePlayer.id);
    }
  }, [ activePlayer, lastActivePlayerId, youreUpAudio, user.disableAudio, user.id ])

  const playerActive = useMemo(() => {
    return activePlayer?.id === user.id;
  }, [ activePlayer, user.id ]);

  if (!user.id) {
    return <Identify />;
  }

  if (error) {
    return <GameError />;
  }

  if (!game.id) {
    return <></>;
  }

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.appBar} color={playerActive ? 'secondary' : 'primary' }>
        <Toolbar>
          <Typography className={classes.appbarTitle} variant="h6" noWrap>
            <Hidden xsDown>
              { game.name ? game.name : 'Unnamed Game'}
              { playerActive && <span> - You're Up!</span>}
              { !playerActive && <span> - Round { activeRound?.sort }</span>}
            </Hidden>
            <Hidden smUp>
              { !playerActive && (game.name ? game.name : 'Unnamed Game')}
              { playerActive && <span>You're Up, Pick a Gift!</span>}
            </Hidden>
          </Typography>
          <Button className={classes.headerButton} onClick={toggleAudio}>
            { user.disableAudio && <MusicOffIcon style={{color: 'white'}}/> }
            { !user.disableAudio && <MusicNoteIcon style={{color: 'white'}}/> }
          </Button>
          <Button className={classes.headerButton} onClick={() => { window.location.href="/" }}><HomeIcon style={{color: 'white'}}/></Button>
        </Toolbar>
      </AppBar>

      <Hidden xsDown>
        <GameDrawer anchor="left">
          { !game.startedAt && <PlayerStatus/> }
          { game.startedAt && <GameStatus /> }
        </GameDrawer>
        <GameDrawer anchor="right"><GameChat/></GameDrawer>
      </Hidden>

      <Hidden smUp>
        <MobileChatDrawer>
          <GameChat/>
        </MobileChatDrawer>
      </Hidden>

      <div className={classes.content}>
        <ReconnectDialog room={match.params.room}/>
        <GameInfo/>
        { <Hidden smUp><GameStatus /></Hidden> }
        { game.completedAt && <GameSummary /> }
        { game.ownerId === user.id && <GameAdmin /> }
        { !game.startedAt && <AddGift /> }
        
        <GamePresents />
      </div>

    </div>
  );
}

export default GameScene;