import { SocketService } from './SocketService';

export class GuestSocket extends SocketService {

  // initiate the connection, provide a namespace to connect to a specific area
  public connect(): SocketService {
    console.debug('GuestSocket.connect');
    this.init();
    return this;
  }
}
