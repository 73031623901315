export function env(value: string) {
  return process.env[`REACT_APP_${value}`];
}

export function socketServer(): string {
  return process.env[`REACT_APP_SOCKET_SERVER`] as string;
}

export default {
  env,
  socketServer
}