export enum GameEvent {
  CONNECT = 'connect',
  DISCONNECT = 'disconnect',
  JOINROOM = 'joinRoom',
  CREATEROOM = 'createRoom',
  ROOMCREATED = 'roomCreated',
  GAMEINFO = 'gameInfo',
  GAMEUPDATE = 'gameUpdate',
  STARTGAME = 'startGame',
  PRESENTADDED = 'presentAdded',
  PICKPRESENT = 'pickPresent',
  PLAYERJOINED = 'playerJoined',
  PLAYERLEFT = 'playerLeft',
  PLAYERDISCONNECTED = 'playerDisconnected',
  ERROR = 'gameError'
}

export enum ChatEvent {
  GETMESSAGES = 'getMessages',
  MESSAGES = 'messages',
  MESSAGE = 'message',
}

export enum UserEvent {
  CONNECT = 'connect',
  IDENTIFY = 'identify',
  UNAUTHORIZED = 'unauthorized',
  LOSTIDENTITY = 'lostIdentity'
}